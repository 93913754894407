<template>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <div class="flex justify-center">
        <h2 class="text-3xl font-bold mb-4 text-black">Transferencia</h2>
      </div>

      <div class="flex justify-center items-center mb-2 mr-5">
        <label for="Monto" class="md:mr-20 text-right text-2xl w-14 mr-8"
          >Monto:</label
        >
        <input
          type="text"
          id="Monto"
          v-model="Monto"
          class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-1/4 text-xl border-green-600 w-1/3 mr-1"
          @input="restrictToNumbers"
        />
      </div>

      <div class="flex justify-center items-center mb-2 mr-5">
        <label for="Sucursal" class="md:mr-20 text-right text-2xl w-14 mr-10"
          >Sucursal:</label
        >
        <select
          v-model="Sucursal"
          id="Sucursal"
          class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-1/4 text-xl border-green-600"
        >
          <option
            v-for="sucursal in sucursales"
            :key="sucursal.id"
            :value="sucursal.id"
          >
            {{ sucursal.nombre }}
          </option>
        </select>
      </div>

      <div class="flex justify-center items-center mb-2 mr-5">
        <button @click="volver" class="btn rojo mr-8">Volver</button>
        <button @click="transferencia" class="btn verde">Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      Monto: "",
      Sucursal: "",
      sucursales: [],
      errorMsg: "",
      loading: true,
      articulo_id: null,
    };
  },
  mounted() {
    this.articulo_id = this.$route.query.articulo_id;
    this.fetchBranches();
  },
  methods: {
    async fetchBranches() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      try {
        const response = await axios.get(`${this.$ruta.value}/branch`, config);
        this.sucursales = response.data;
      } catch (error) {
        console.error("Error al enviar el mensaje:", error);
        if (error.response && [401, 422].includes(error.response.status)) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        }
      } finally {
        this.loading = false;
      }
    },
    async transferencia() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      const payload = {
        article_id: this.articulo_id,
        amount: this.Monto,
        from_branch_id: this.$id_selected.value,
        to_branch_id: this.Sucursal,
        date: "2024-05-20 15:30:00",
      };

      console.log("Datos enviados:", payload);

      try {
        const response = await axios.post(
          `${this.$ruta.value}/article-transfer`,
          payload,
          config
        );
        alert(response.data.message);
        router.push("/existenciaproductos");
      } catch (error) {
        console.error("Error al enviar el mensaje:", error);
        if (error.response && error.response.data) {
          alert(error.response.data.message);
          console.error("Server response:", error.response.data);
        }
        if (error.response && [401, 422].includes(error.response.status)) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        }
      }
    },
    restrictToNumbers(event) {
      event.target.value = event.target.value.replace(/[^0-9.]/g, "");
    },
    volver() {
      window.history.back();
    },
  },
};
</script>
