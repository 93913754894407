<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <h2 class="text-3xl font-bold mb-4 text-black">Turnos</h2>
      <br />

      <table class="bg-custom-green w-full border-2 rounded-lg mx-auto">
        <thead>
          <tr class="bg-custom-green">
            <th class="py-4 px-6 text-xl">Fecha</th>
            <th class="py-4 px-6 text-xl">Trabajador</th>
            <th class="py-4 px-6 text-xl">Sucursal</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(turno) in turnos"
            :key="turno.id"
            @click="redirigir(turno.id)"
            class="cursor-pointer"
          >
            <td class="bg-white text-center text-xl">{{ turno.fecha }}</td>
            <td class="bg-white text-center text-xl">{{ turno.trabajador }}</td>
            <td class="bg-white text-center text-xl">{{ turno.sucursal }}</td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "TurnosComp",
  components: {
    Spinner
  },
  data() {
    return {
      turnos: [],
      loading: true
    };
  },
  mounted(){
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/getTurns`, config)
      .then((response) => {
        this.turnos=response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    redirigir(id) {
      this.$id_selected.value = id;
      router.push("/detalleturno");
    },
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
  /* Color con 40% de opacidad */
}
</style>
