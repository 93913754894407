<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <SelecSucursal :sucursales="sucursales" />
  </div>
</template>

<script>
import SelecSucursal from "../components/SelecSucursalComp.vue";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "SelecSucursalView",
  components: {
    SelecSucursal,
    Spinner,
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    axios
      .get(`${this.$ruta.value}/branch`, config)
      .then((response) => {
        this.sucursales = response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      sucursales: [],
      loading: true,
    };
  },
};
</script>
