<template>
    <div>
      <Cuentas />
    </div>
  </template>
  
  <script>
  import Cuentas from "../components/CuentasComp.vue";
  
  export default {
    components: {
      Cuentas,
    },
  };
  </script>
  