<template>
    <Sucursales></Sucursales>
  
  </template>
  
  <script>
  import Sucursales from "../components/SucursalesComp.vue";
  
  export default {
    name: "SucursalesView",
    components: {
      Sucursales,
      
    },
  };
  </script>