<template>
    <Actualizar :labelText="labelText" :tipo="true"/>
</template>

<script>
import Actualizar from "../components/ActualizarDatoProductoComp.vue";

export default {
  components: {
    Actualizar,
  },
  data() {
    return {
      labelText: "Precio",
    };
  },
  methods: {},
};
</script>
