import { createApp, reactive } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/style.css';
import './assets/tailwind.css'

const app = createApp(App).use(store).use(router)

app.config.globalProperties.$token = reactive({ value: 'null' });
app.config.globalProperties.$userType = reactive({ value: localStorage.getItem('userType') });
app.config.globalProperties.$id_selected = reactive({ value: 'null' , value2: 'null', value3: 'null' });
app.config.globalProperties.$claveApi = reactive({ value: '123456789' });
app.config.globalProperties.$sucursal = reactive({ value: false });
app.config.globalProperties.$ruta = reactive({ value: 'https://api-vp.jarochofruteria.com/api' });

app.mount('#app')
