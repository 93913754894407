<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <h2 class="text-3xl font-bold mb-4 text-black">Cuentas</h2>
      <br />
      <div class="overflow-x-auto">
        <table class="bg-custom-green w-full border-green-600 border-2 rounded-lg">
          <thead>
            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-xl">Nombre</th>
              <th class="py-4 px-6 text-xl">Adeudo</th>
              <th class="py-4 px-6 text-xl">Ultimo Pago</th>
              <th class="py-4 px-6 text-xl">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(cuenta) in cuentas" :key="cuenta.id" @click="redirigir(cuenta.id)" class="cursor-pointer">
              <td class="bg-white text-center text-xl">{{ cuenta.nombre }}</td>
              <td class="bg-white text-center text-xl">${{ cuenta.adeudo }}</td>
              <td class="bg-white text-center text-xl">{{ cuenta.ultimopago }}</td>
              <td :class="{
                'text-green-500 bg-white text-xl': cuenta.estado === '1',
                'bg-white': cuenta.estado !== '1',
              }" class="text-center text-xl">
                <div v-if="cuenta.estado === '1'">
                  Pagado
                </div>
                <div v-else>
                  No pagado
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "cuentasComp",
  components: {
    Spinner
  },
  data() {
    return {
      cuentas: [],
      loading: true
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/showDebts`, config)
      .then((response) => {
        this.cuentas = response.data.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    redirigir($id) {
      console.log($id);
      this.$id_selected.value = $id;
      router.push("/detallecuenta");
    },
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}
</style>