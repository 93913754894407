<template>
  <div class="flex flex-col items-center mt-8">
    <h2 class="text-xl font-bold mb-4">Registro de Gastos</h2>
    <div class="mb-4">
      <label for="monto" class="text-lg text-black">Monto:</label>
    </div>
    <div class="mb-4">
      <input
        type="text"
        id="monto"
        v-model="monto"
        class="border border-green-500 rounded-md px-3 py-2 w-64"
        @input="restrictToNumbers"
      />
    </div>
    <div class="mb-4">
      <label for="descripcion" class="text-lg text-black">Descripción:</label>
    </div>
    <div class="mb-4">
      <input
        type="text"
        id="descripcion"
        v-model="descripcion"
        class="border border-green-500 rounded-md px-3 py-2 w-64"
      />
    </div>
    <div class="mt-4 flex justify-between w-64">
      <button @click="volver" class="btn rojo">Volver</button>
      <button @click="aceptar" class="btn verde">Aceptar</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  data() {
    return {
      monto: "",
      descripcion: "",
    };
  },
  methods: {
    restrictToNumbers(event) {
    // Filtrar el contenido para permitir solo números
    event.target.value = event.target.value.replace(/\D/g, '');
  },
    volver() {
      router.push('/perfil');
    },
    aceptar(){
      const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .post(`${this.$ruta.value}/cashOut`, 
      {
        price : this.monto,
        description: this.descripcion,
      }
      ,config)
      .then((response) => {
        alert(response.data.message);
        router.push('/productos');
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      });
    }
  },
};
</script>
<style scoped>
@import "../assets/tailwind.css";
</style>
