<template>
  <Carrito />
  <asignar />
</template>

<script>
import Carrito from "../components/CarritoComp.vue";

export default {
  name: "CarritoView",
  components: {
    Carrito,
  },
};
</script>
