<template>
    <Turnos></Turnos>
  
  </template>
  
  <script>
  import Turnos from "../components/TurnosComp.vue";
  
  export default {
    name: "TurnosView",
    components: {
      Turnos,
      
    },
  };
  </script>