<template>
  <div class="flex justify-center items-center mt-16">
    <div class="w-full max-w-screen-xl mt-3">
      <div class="flex justify-center">
        <!-- Utiliza la variable tituloProp para cambiar dinámicamente el texto del h2 -->
        <h2 class="text-3xl font-bold mb-6 text-black">
          {{ tituloProp }}
        </h2>
      </div>

      <div class="flex justify-center">
        <div class="w-full max-w-custom-width mt-5">
          <div class="flex flex-col mb-2">
            <!-- Utiliza la variable camposProp.nombre.texto para cambiar dinámicamente el texto del label -->
            <label :for="camposProp.nombre.id" class="text-2xl mb-3">{{
              camposProp.nombre.texto
            }}</label>
            <input :type="camposProp.nombre.type" :id="camposProp.nombre.id" v-model="nombre"
              class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl" :class="{
                'border-green-600': true,
              }" />
          </div>
          <br />

          <div class="flex flex-col mb-2">
            <!-- Utiliza la variable camposProp.direccion.texto para cambiar dinámicamente el texto del label -->
            <label :for="camposProp.direccion.id" class="text-2xl mb-3">{{
              camposProp.direccion.texto
            }}</label>
            <input :type="camposProp.direccion.type" :id="camposProp.direccion.id" v-model="direccion"
              class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl" :class="{
                'border-green-600': true,
              }" />
          </div>
          <br />
        </div>
      </div>

      <br />
      <div class="flex justify-center items-center">

        <button @click="volver" class="btn rojo mr-8">Volver</button>
        <div v-if="edicion">
          <button @click="editarSucursal" class="btn verde">Guardar</button>
        </div>
        <div v-else>
          <button @click="registrarSucursal" class="btn verde">Guardar</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from 'axios';
export default {
  name: "NuevaSucursalComp",
  props: {
    tituloProp: String,
    camposProp: Object,
    edicion: Boolean,
  },
  data() {
    return {
      nombre: "",
      dirección: "",
    };
  },

  methods: {
    volver() {
      router.push("/sucursales");
    },
    registrarSucursal() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .post(`${this.$ruta.value}/registerBranch`, {
          name: this.nombre,
          addres: this.direccion,

        }, config)
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
      window.history.back();
    },
    editarSucursal() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .put(`${this.$ruta.value}/updateAddres/${this.$id_selected.value}`, {
          name: this.nombre,
          addres: this.direccion,
        }, config)
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
      window.history.back();
    }
  },
};
</script>

<style scoped>
.max-w-custom-width {
  max-width: 300px;
}
</style>
