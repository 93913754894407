<template>
  <div class="w-80 ml-5 grid grid-cols-3 gap-1">
    <button
      v-for="(number, index) in numbers"
      :key="index"
      class="w-full h-10 text-xl border border-green-600 flex items-center justify-center rounded bg-custom-hover-color"
      @click="addToInput(number)"
    >
      {{ number }}
    </button>
    <button
      v-if="numbers.includes('0')"
      class="text-black hover:bg-red-600 bg-custom-hover-red font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline border border-green-600 text-sm w-26 h-10 flex items-center justify-center"
      @click="deleteLastDigit"
    >
      &#x232b;
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numbers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "0"],
      inputElement: null, 
    };
  },
  mounted() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('focus', () => {
        this.inputElement = input;
      });
    });
  },
  methods: {
    addToInput(number) {
      if (this.inputElement) {
        this.inputElement.value += number;
        this.$emit('input-updated', this.inputElement.value);
      }
    },
    deleteLastDigit() {
      if (this.inputElement) {
        this.inputElement.value = this.inputElement.value.slice(0, -1);
        this.$emit('input-updated', this.inputElement.value);
      }
    },
  },
};
</script>

<style scoped>
.bg-custom-hover-color:hover {
  background-color: rgba(92, 203, 95, 0.6); /* Cambia el color de fondo en hover */
}

.bg-custom-hover-red:hover {
  background-color: rgba(255, 112, 112, 0.8); /* Cambia el color de fondo en hover */
}
</style>