<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <h2 class="text-3xl font-bold mb-4 text-black">Detalles de la Venta</h2>
      <br />

      <div class="overflow-x-auto">
        <div class="table-container">
          <table class="w-full md:w-auto">
            <tr>
              <th class="w-1/5 bg-custom-green text-2xl border-2 border-green-600 rounded-l-lg">
                Id Venta
              </th>
              <th class="w-1/5 text-2xl border-2 border-green-600 rounded-lg text-center">
                {{venta_info.id}}
              </th>
              <th class="w-1/5 bg-white border-2 border-white text-center md:border-r"></th>
              <th class="bg-white text-center md:border-r"></th>
              <th class="bg-custom-green w-1/5 text-2xl border-2 border-green-600">
                Hora
              </th>
              <th class="w-1/5 text-2xl border-2 border-green-600 rounded-r-lg text-center">
                {{venta_info.hora}}
              </th>
            </tr>
          </table>
        </div>
      </div>

      <div class="table-container">
        <table class="bg-custom-green w-full border-2 border-green-600 rounded-lg mt-6">
          <thead>
            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-2xl border-2 border-green-600 rounded-lg text-center">
                Producto
              </th>
              <th class="py-4 px-6 text-2xl border-2 border-green-600 rounded-lg text-center">
                Cantidad
              </th>
              <th class="py-4 px-6 text-2xl border-2 border-green-600 rounded-lg text-center">
                Precio Unitario
              </th>
              <th class="py-4 px-6 text-2xl border-2 border-green-600 rounded-lg text-center">
                Sub Total
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- Aquí se iteran las filas existentes -->
            <tr v-for="(producto,index) in venta_info.productos" :key="index">
              <td class="bg-white text-xl border-2 border-green-600 rounded-lg text-center">
                {{ producto.nombre }}
              </td>
              <td class="bg-white text-2xl border-2 border-green-600 rounded-lg text-center">
                {{ producto.cantidad }} Kg
              </td>
              <td class="bg-white text-2xl border-2 border-green-600 rounded-lg text-center">
                $ {{ producto.precio_unitario }}
              </td>
              <td class="bg-white text-2xl border-2 border-green-600 rounded-lg text-center">
                $ {{ producto.subtotal }}
              </td>
            </tr>
            <tr class="bg-custom-green">
              <td class="bg-white border-2 border-white text-center border-r" colspan="2"></td>
              <td class="py-4 px-6 text-2xl font-bold border-2 border-green-600 rounded-lg text-center" colspan="1">
                Total:
              </td>
              <td class="bg-white text-2xl border-2 border-green-600 rounded-lg text-center" colspan="2">
                ${{venta_info.total}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />

      <div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
        <button class="btn verde text-xl" style="margin-right: 20px" @click="goBack()">
          Aceptar
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "CarritoComp",
  components: {
    Spinner
  },
  data() {
    return {
      venta_info: [],
      loading: true
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/selectSale/${this.$id_selected.value2}`, config)
      .then((response) => {
        this.venta_info = response.data.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    goBack() {
      window.history.back();
    }
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}
</style>
