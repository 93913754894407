<template>
    <MenuPedidos />
  </template>
  
  <script>
  import MenuPedidos from "../components/MenuPedidosComp.vue";
  
  export default {
    components: {
      MenuPedidos,
    },
  };
  </script>
  