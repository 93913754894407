<template>
  <div>
    <caja />
  </div>
</template>

<script>
import caja from "../components/CorteCajaComp.vue";

export default {
  components: {
    caja,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
