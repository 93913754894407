<template>
  <nav class="bg-white">
    <div class="flex justify-between items-center">
      <img src="@/assets/logojarocho.png" alt="Logo" class="lg:h-24 lg:w-24  md:w-24 md:h-24 w-16 h-16 ml-6" />

      <div class="flex font-bold overflow-auto mr-4 ml-auto">
        <!-- Admin -->
        <template v-if="user_type == 'Admin'">
          <router-link
            to="/menuproductos"
            class="ml-8 mr-6"
            :class="{ 'text-green-500': $route.path == '/menuproductos' }"
          >
            <span class="lg:text-xl md:text-xl ">Productos</span>
          </router-link>
          <router-link
            to="/turnos"
            class="ml-8"
            :class="{ 'text-green-500': $route.path == '/turnos' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Gestión</span>
          </router-link>
          <router-link
            to="/menupedidos"
            class="ml-8"
            :class="{ 'text-green-500': $route.path == '/menupedidos' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Pedidos</span>
          </router-link>
          <router-link
            to="/usuarios"
            class="ml-8"
            :class="{ 'text-green-500': $route.path == '/usuarios' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Usuarios</span>
          </router-link>
          <router-link
            to="/perfil"
            class="ml-8 mr-3"
            :class="{ 'text-green-500': $route.path == '/perfil' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Perfil</span>
          </router-link>
        </template>

        <!-- Cliente -->
        <template v-else-if="user_type == 'Client'">
          <router-link
            to="/productos"
            class="ml-12"
            :class="{ 'text-green-500': $route.path == '/productos' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Productos</span>
          </router-link>
          <router-link
            to="/detalleCuenta"
            class="ml-12"
            :class="{ 'text-green-500': $route.path == '/detalleCuenta' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Mis Cuentas</span>
          </router-link>
          <router-link
            to="/perfil"
            class="ml-12 mr-3"
            :class="{ 'text-green-500': $route.path == '/perfil' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Perfil</span>
          </router-link>
        </template>

        <!-- Trabajador -->
        <template v-else>
          <router-link
            to="/productos"
            class="ml-10"
            :class="{ 'text-green-500': $route.path == '/productos' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Productos</span>
          </router-link>
          <router-link
            to="/registro"
            class="ml-10"
            :class="{ 'text-green-500': $route.path == '/registro' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Registro</span>
          </router-link>
          <router-link
            to="/pedidos"
            class="ml-10"
            :class="{ 'text-green-500': $route.path == '/pedidos' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Pedidos</span>
          </router-link>
          <router-link
            to="/perfil"
            class="ml-8 mr-3"
            :class="{ 'text-green-500': $route.path == '/perfil' }"
          >
            <span class="lg:text-xl md:text-xl lg:mr-6 md:mr-4">Perfil</span>
          </router-link>
        </template>
      </div>

      <!-- Worker -->
      <template v-if="user_type == 'Worker'">
        <router-link to="/carrito">
          <img
            src="@/assets/Vector.png"
            alt="Carrito"
            class="h-8 w-8 mt-0 mr-10"
            @click="abrircarrito"
          />
        </router-link>
      </template>

      <!-- Admin -->
      <template v-if="user_type == 'Admin'">
        <router-link to="/sucursales">
          <img
            src="@/assets/Group.png"
            alt="Sucursales"
            class="h-8 w-8 mt-0 mr-10"
            @click="abrirSucursales"
          />
        </router-link>
      </template>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      user_type: "",
    };
  },
  methods: {
    abrircarrito() {
      this.$emit("/carrito");
    },
    abrirSucursales() {
      this.$emit("/sucursales");
    },
  },
  mounted() {
    this.user_type = localStorage.getItem("userType");
  },
};
</script>
