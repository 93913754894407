<template>
    <Abonar />
  </template>
  
  <script>
  import Abonar from "../components/AbonarComp.vue";
  
  export default {
    name: "AbonarView",
    components: {
      Abonar,
    },
    data() {
      return {
      };
    },
  };
  </script>
  