<template>
    <DetallesTurno></DetallesTurno>
  
  </template>
  
  <script>
  import DetallesTurno from "../components/DetallesTurnoComp.vue";
  
  export default {
    name: "DetallesTurnoView",
    components: {
      DetallesTurno,
      
    },
  };
  </script>