<template>
  <div class="flex justify-center items-center mt-20">
    <div class="w-full max-w-screen-xl mt-3">
      <div class="flex justify-center">
        <h2 class="text-3xl font-bold mb-6 text-black">
          Ingresé el monto a abonar!
        </h2>
      </div>

      <div class="flex justify-center">
        <div class="w-full max-w-custom-width mt-5">
          <div class="flex flex-col mb-2">
            <label :for="monto" class="text-2xl mb-3"> Monto </label>
            <input
              :type="text"
              :id="monto"
              v-model="monto"
              class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl"
              :class="{
                'border-green-600': true,
              }"
              @input="validateInput"
            />
          </div>
          <br />
        </div>
      </div>

      <br />
      <div class="flex justify-center items-center">
        <button @click="goBack" class="btn rojo mr-8">Cancelar</button>
        <button @click="abonar" class="btn verde">Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "AbonarComp",
  data() {
    return {
      monto:0,
    };
  },

  methods: {
    goBack() {
      window.history.back();
    },
    validateInput(event) {
      let value = event.target.value;

      // Reemplazar cualquier caracter que no sea un número o punto decimal
      value = value.replace(/[^\d.]/g, "");

      // Verificar si ya hay un punto decimal en el valor
      if ((value.match(/\./g) || []).length > 1) {
        // Si hay más de un punto decimal, eliminar los caracteres adicionales
        value = value.slice(0, value.lastIndexOf("."));
      }

      // Actualizar el valor del campo
      this.monto = value;
    },
    abonar(){
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .post(`${this.$ruta.value}/payDebt`,
        {
          id:this.$id_selected.value,
          amount: this.monto
        }
        ,config)
        .then((response) => {
          alert(response.data.message);
          window.history.back()
        })
        .catch((error) => {
          alert(error.response.data.message);
          console.error("Error al enviar el mensaje:", error);
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
          //this.mostrarError = true;
          }
        });
    }
  },
};
</script>

<style scoped>
.max-w-custom-width {
  max-width: 300px;
}
</style>
