<template>
    <CompraProducto :producto="producto" />
</template>

<script>
import CompraProducto from "../components/CompraProducto.vue";
export default {
  name: "AgregarProductoView",
  components: {
    CompraProducto,
  },
  data() {
    return {
      producto: [
        {
          id: 1,
          nombre: "Manzana",
          imagen: require("@/assets/manzana.png"),
        },
      ],
    };
  },

};
</script>
