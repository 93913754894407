<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
    <ListaPreciosComp :Text="Text" :productos="productos"></ListaPreciosComp>
  </div>
  </template>
  
  <script>
  import ListaPreciosComp from "../components/ListaPreciosComp.vue";
  import axios from "axios";
  import { Spinner } from "vue-spinners";
  export default {
    name: "ListaProductosView",
    components: {
      ListaPreciosComp,
      Spinner
    },
    
    data() {
      return {
        Text: "Precios",
        productos: [],
        loading: true
      };
    },
    mounted(){
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/articles`, config)
      .then((response) => {
        this.productos = response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
    }
  };
  </script>
  