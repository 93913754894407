<template>
  <div class="flex items-center mt-8">
      <h2 class="lg:ml-20 ml-2 md:ml-10 text-3xl font-bold text-black">{{ Text }}</h2>
    </div>
  <div
    v-for="(producto, index) in productos"
    :key="index"
    class="bg-custom-green border-green-600 border-2 rounded-lg mt-8 mr-3 ml-3 h-20 relative md:ml-10 md:mr-10 lg:ml-20 lg:mr-20 cursor-pointer"
    @click="redirigir(producto.id)"
  >
    <div class="flex justify-start">
      <img
        :src="producto.imagen"
        :alt="producto.nombre"
        class="w-15 h-14 mt-3 ml-5"
      />
      <div class="mt-6 ml-6 text-2xl">{{ producto.nombre }}</div>
    </div>
    <div class="absolute top-0 right-0 font-bold mt-6 mr-12 text-xl">
      $ {{ producto.precio }}
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "ProductosComp",
  props: {
    Text: {
      type: String,
    },
    productos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    redirigir($id){
      this.$id_selected.value2 = $id;
      router.push("/actualizarprecio");
    },
  },
};
</script>

<style>
.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}
</style>
