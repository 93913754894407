<template>
  <div class="login-fondo">
    <div class="fixed inset-0 flex justify-center items-center">
      <div class="bg-white p-8 rounded-lg relative lg:w-2/5 md:w-4/5">
        <div class="sticky top-0 inset-x-0 bg-white z-10 p-4">
          <div class="text-center font-bold text-xl mb-4">Sucursales</div>
          <p class="text-center mb-4">
            Por favor, selecciona la sucursal en la que iniciarás tu jornada
            laboral hoy.
          </p>
          <div class="font-bold text-lg ml-2">Dirección</div>
        </div>

        <div class="h-72 overflow-y-auto ml-3 mb-6 pb-6 mr-2">
          <div
            v-for="(sucursal, index) in sucursales"
            :key="index"
            class="flex items-center justify-between ml-2 mb-3 align-middle"
            :class="{
              'bg-gradient-to-r from-green-200 via-green-100 to-green-200 rounded-lg':
                selectedSucursal === sucursal.id,
            }"
            @click="toggleEstado(sucursal.id)"
          >
            <p class="flex-grow truncate">{{ sucursal.nombre }}</p>
            <label class="switch">
              <input
                type="checkbox"
                v-model="sucursal.estado"
                :disabled="true"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="absolute bottom-4 inset-x-0 flex justify-center pb-6">
          <button @click="seleccionar" class="btn amarillo w-full ml-7 mr-7">
            Ingresar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
export default {
  props: {
    sucursales: {
      type: Array,
    },
  },
  methods: {
    toggleEstado(sucursalId) {
      const sucursal = this.sucursales.find((s) => s.id === sucursalId);
      if (sucursal.estado) {
        this.selectedSucursal = sucursalId;
      }
    },
    seleccionar() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      if (!localStorage.getItem("shoping_id")) {
        axios
          .get(`${this.$ruta.value}/createShopingCar`, config)
          .then((response) => {
            localStorage.setItem("shoping_id", response.data);
          })
          .catch((error) => {
            console.error("Error al enviar el mensaje:", error);
            if (
              error.response &&
              (error.response.status === 401 || error.response.status === 422)
            ) {
              this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
            } else {
              //this.mostrarError = true;
            }
          });
      }

      axios
        .post(
          `${this.$ruta.value}/startTurn`,
          {
            branch_id: this.selectedSucursal,
            in_charge: localStorage.getItem("id"),
          },
          config
        )
        .then((response) => {
          alert(response.data.message);
          localStorage.setItem("sucursal", this.selectedSucursal);
          this.$sucursal.value = true;
          localStorage.setItem("turn", response.data.turn);
          router.push("/productos");
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
    },
  },
  data() {
    return {
      selectedSucursal: null,
    };
  },
};
</script>

<style scoped>
@import "@/assets/tailwind.css";
input[type="checkbox"] {
  appearance: none;
}
</style>
