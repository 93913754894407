<template>
  <div>
    <contraseña />
  </div>
</template>

<script>
import contraseña from "../components/NuevaContraseñaComp.vue";

export default {
  components: {
    contraseña,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
