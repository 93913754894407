<template>
    <EditUsuario></EditUsuario>
  
  </template>
  
  <script>
  import EditUsuario from "../components/EditUsuarioComp.vue";
  
  export default {
    name: "EditUsuarioView",
    components: {
      EditUsuario,
      
    },
  };
  </script>