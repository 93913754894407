<template>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-8 m-4">
    <router-link
      v-for="(item, index) in menu"
      :key="index"
      :to="item.route"
      class="w-full"
    >
      <div
        class="bg-custom-green border-green-600 border-2 rounded-lg cursor-pointer p-4 sm:mt-10 flex flex-col justify-between items-center"
        style="height: 300px"
      >
        <div class="flex flex-col justify-center items-center h-full mx-auto">
          <img :src="item.image" class="w-40 h-40 mb-4" />
          <div class="text-center font-bold text-2xl">{{ item.name }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          name: "Registrar Productos",
          image: require("@/assets/registrarproductos.png"),
          route: "/registrarproductos",
        },
        {
          name: "Precios",
          image: require("@/assets/precio.png"),
          route: "/precios",
        },
        {
          name: "Existencias",
          image: require("@/assets/existencias.png"),
          route: "listsucursal",
        },
        {
          name: "Transferencias",
          image: require("@/assets/transferencias.png"),
          route: "/showtransferencia",
        },
      ],
    };
  },
};
</script>
