<template>
    <Registrar/>
</template>

<script>
import Registrar from "../components/RegistrarProductoComp.vue";

export default {
  components: {
    Registrar,
  },
  methods: {},
};
</script>
