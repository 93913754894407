<template>
  <Sucursales />
</template>

<script>
import Sucursales from "../components/ListSucursalesComp.vue";

export default {
  name: "ListSucursalView",
  components: {
    Sucursales,
  },
};
</script>
