<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-center items-start h-screen">
      <div class="w-full max-w-screen-xl mt-10">
        <h2 class="text-3xl font-bold mb-4 text-black">Transferencias</h2>
        <br />
        <div
          class="overflow-x-auto md:overflow-x-visible md:overflow-y-visible"
        >
          <table
            class="bg-custom-green w-full border-green-600 border-2 rounded-lg"
          >
            <thead>
              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl">Articulo</th>
                <th class="py-4 px-6 text-xl">Monto</th>
                <th class="py-4 px-6 text-xl">Sucursal</th>
                <th class="py-4 px-6 text-xl">Tipo</th>
                <th class="py-4 px-6 text-xl">Fecha</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in datos" :key="item.id">
                <td class="bg-white text-center text-xl">
                  {{ item.article_name}}
                </td>
                <td class="bg-white text-center text-xl">{{ item.amount }} kg</td>
                <td class="bg-white text-center text-xl">
                  {{ item.branch_name }}
                </td>
                <td class="bg-white text-center text-xl">
                  <span
                    v-if="item.type === 'transfer_out'"
                    class="material-symbols-outlined text-red-500"
                  >
                    arrow_downward
                  </span>
                  <span
                    v-else-if="item.type === 'transfer_in'"
                    class="material-symbols-outlined text-green-500"
                  >
                    arrow_upward
                  </span>

                  <span v-else>{{ item.type }}</span>
                </td>

                <td class="bg-white text-center text-xl">{{ item.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  data() {
    return {
      datos: [],
      loading: true,
    };
  },
  components: {
    Spinner,
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    axios
      .get(`${this.$ruta.value}/show-article-transfer`, config)
      .then((response) => {
        console.log(response.data);
        this.datos = response.data.data; 
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {},
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}
</style>
