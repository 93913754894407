import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProductosView from "../views/ProductosView.vue";
import RegistroView from "../views/RegistroView.vue";
import PedidosView from "../views/PedidosView.vue";
import DetalleCuentaView from "../views/DetalleCuentaView.vue";
import TurnosView from "../views/TurnosView.vue";
import DetallesTurnoView from "../views/DetallesTurnoView.vue";
import PerfilView from "../views/PerfilView.vue";
import LoginView from "../views/LoginView.vue";
import SelecSucursalView from "../views/SelecSucursalView.vue";
import UsuariosView from "../views/UsuariosView.vue";
import SucursalesView from "../views/SucursalesView.vue";
import NuevoUsuarioView from "../views/NuevoUsuarioView.vue";
import EditUsuarioView from "../views/EditUsuarioView.vue";
import NuevaSucursalView from "../views/NuevaSucursalView.vue";
import CarritoView from "../views/CarritoView.vue";
import MenuProductosView from "../views/MenuProductosView.vue";
import MenuPedidosView from "../views/MenuPedidosView.vue";
import DetallePedidosView from "../views/DetallePedidosView.vue"
import AgregarProductoView from "../views/AgregarProductoView.vue"
import PreciosView from "../views/PreciosView.vue"
import ExistenciaProductosView from "@/views/ExistenciaProductosView.vue";
import NuevaContraseñaView from "@/views/NuevaContraseñaView.vue"
import RegistroGastosView from "@/views/RegistroGastosView"
import CorteCajaView from "@/views/CorteCajaView"
import ActualizarPrecioView from "@/views/ActualizarPrecioView.vue"
import ActualizarCantidadView from "@/views/ActualizarCantidadView.vue"
import AsignarPedidosView from "@/views/AsignarPedidosView"
import CuentasView from "@/views/CuentasView"
import EditSucursalView from "@/views/EditSucursalView"
import RegistrarProductoView from "@/views/RegistrarProductoView"
import DetalleVentaView from "@/views/DetalleVentaView"
import ListSucursalView from "@/views/ListSucursalView"
import VerTransaccionView from "@/views/VerTransaccionView"
import AbonarView from "@/views/AbonarView.vue";
import Transferencia from "@/views/TransferenciaView.vue"
import ShowTransferencia from "@/views/ShowTransferenciaView.vue"
const routes = [
  {
    path: "/inicio",
    name: "home",
    component: HomeView,
  },

  {
    path: "/productos",
    name: "productos",
    component: ProductosView,
  },
  {
    path: "/registro",
    name: "registros",
    component: RegistroView,
  },
  {
    path: "/precios",
    name: "precios",
    component: PreciosView,
  },
  {
    path: "/existenciaproductos",
    name: "ExistenciaProductosView",
    component: ExistenciaProductosView,
  },
  {
    path: "/pedidos",
    name: "pedidos",
    component: PedidosView,
  },
  {
    path: "/detallecuenta",
    name: "DetalleCuentaView",
    component: DetalleCuentaView,
  },
  {
    path: "/turnos",
    name: "turnos",
    component: TurnosView,
  },
  {
    path: "/detalleturno",
    name: "detalleturno",
    component: DetallesTurnoView,
  },
  {
    path: "/perfil",
    name: "perfil",
    component: PerfilView,
  },
  {
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    path: "/selecSucursal",
    name: "SelecSucursalView",
    component: SelecSucursalView,
  },
 
  {
    path: "/usuarios",
    name: "UsuariosView",
    component: UsuariosView,
  },
  {
    path: "/sucursales",
    name: "SucursalesView",
    component: SucursalesView,
  },
  {
    path: "/nuevousuario",
    name: "NuevoUsuarioView",
    component: NuevoUsuarioView,
  },
  {
    path: "/editarusuario",
    name: "EditUsuarioView",
    component: EditUsuarioView,
  },
  {
    path: "/nuevasucursal",
    name: "NuevaSucursalView",
    component: NuevaSucursalView,
  },
  {
    path: "/carrito",
    name: "CarritoView",
    component: CarritoView,
  },
  {
    path: "/menuproductos",
    name: "MenuProductosView",
    component: MenuProductosView,
  },
  {
    path: "/menupedidos",
    name: "MenuPedidosView",
    component: MenuPedidosView,
  },
  {
    path: "/detallepedidos",
    name: "DetallePedidosView",
    component: DetallePedidosView,
  },
  {
    path: "/agregarproducto",
    name: "AgregarProductoView",
    component: AgregarProductoView,
  },
  {
    path: "/actualizarcontraseña",
    name: "NuevaContraseñaView",
    component: NuevaContraseñaView,
  },
  {
    path: "/registragastos",
    name: "RegistroGastosView ",
    component: RegistroGastosView ,
  },
  {
    path: "/cortecaja",
    name: "CorteCajaView",
    component: CorteCajaView,
  },
  {
    path: "/actualizarprecio",
    name: "ActualizarPrecioView",
    component: ActualizarPrecioView,
  },
  {
    path: "/actualizarcantidad",
    name: "ActualizarCantidadView",
    component: ActualizarCantidadView,
  },
  {
    path: "/asignarpedido",
    name: "AsignarPedidosView",
    component: AsignarPedidosView,
  },
  {
    path: "/cuentas",
    name: "CuentasView",
    component: CuentasView,
  },
  {
    path: "/editarsucursal",
    name: "EditSucursalView",
    component: EditSucursalView,
  },
  {
    path: "/registrarproductos",
    name: "RegistrarProductoView",
    component: RegistrarProductoView,
  },
  {
    path: "/detalleventa",
    name: "DetalleVentaView ",
    component: DetalleVentaView ,
  },
  {
    path: "/listSucursal",
    name: "ListSucursalView",
    component: ListSucursalView,
  },
  {
    path: "/vertransaccion",
    name: "VerTransaccionView",
    component: VerTransaccionView,
  },
  {
    path: "/abonar",
    name: "AbonarView",
    component: AbonarView,
  },
  {
    path: "/transferencia",
    name: "Transferencia ",
    component:Transferencia,
  },
  {
    path: "/showtransferencia",
    name: "ShowTransferenciaView ",
    component:ShowTransferencia,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
