<template>
  <MenuProductos />
</template>

<script>
import MenuProductos from "../components/MenuProductosComp.vue";

export default {
  components: {
    MenuProductos,
  },
};
</script>
