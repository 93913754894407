<template>
  <div id="app">
    
    <div v-if="user_type === 'Admin' || (user_type === 'Worker' && this.$sucursal.value) || user_type === 'Client'">
      <NavWorker />
      <Barra />
    </div>
    <router-view />
  </div>  
</template>

<script>
import NavWorker from "./components/BarraNavComp.vue";
import Barra from "./components/BarraComp.vue";
import CryptoJS from "crypto-js";

export default {
  components: {
    NavWorker,
    Barra,
  },
  data() {
    return {
      user_type: "",
    };
  },
  mounted() {
    const tokenFromStorage = localStorage.getItem('Token');
    const claveApi = this.$claveApi.value;
    if (tokenFromStorage && claveApi) {
        const bytes = CryptoJS.AES.decrypt(tokenFromStorage, claveApi);
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
        this.$token.value = decryptedToken;
    }
    this.user_type = localStorage.getItem("userType");
    if(localStorage.getItem('sucursal')){
      this.$sucursal.value = true;
    }
    this.$router.push("/");
  },
  
};
</script>
