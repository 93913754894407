<template>
  <div class="flex flex-col items-center mt-8">
    <h2 class="text-xl font-bold mb-4">Ingrese los datos del pedido</h2>
    <div class="mb-4">
      <label for="telefono" class="text-lg text-black">Telefono del cliente:</label>
    </div>
    <div class="mb-4">
      <input
        type="text"
        id="telefono"
        v-model="telefono"
        class="border border-green-500 rounded-md px-3 py-2 w-64"
      />
    </div>
    <div class="mb-4">
      <label for="descripcion" class="text-lg text-black">Direccion:</label>
    </div>
    <div class="mb-4">
      <input
        type="text"
        id="descripcion"
        v-model="descripcion"
        class="border border-green-500 rounded-md px-3 py-2 w-64"
      />
    </div>
    <div class="mt-4 flex justify-between w-64">
      <button @click="volver" class="btn rojo">Volver</button>
      <button @click="aceptar" class="btn verde">Aceptar</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '@/router';
export default {
  data() {
    return {
      telefono: '',
      descripcion: ''
    }
  },
  methods: {
    volver() {
      this.$emit("cerrarComponentes");
      this.$router.push({ name: "CarritoView" });
    },
    aceptar() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios.post(`${this.$ruta.value}/addOrder`, {
        shoping_id: localStorage.getItem('shoping_id'),
        branch_id: localStorage.getItem('sucursal'),
        phone: this.telefono,
        amount: 100 // Aquí puedes poner un valor fijo o ajustar según sea necesario
      }, config)
      .then(() => {
        alert('El pedido se asigno correctamente');
        return axios.get(`${this.$ruta.value}/createShopingCar`, config);
      })
      .then((response) => {
        localStorage.setItem("shoping_id", response.data);
        router.push("/productos");
      })
      .catch((error) => {
        alert(error.response.data.message);
        if (error.response &&
            (error.response.status === 401 || error.response.status === 422)
        ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
            //this.mostrarError = true;
        }
      });
    }
  }
}
</script>
<style scoped>
@import "../assets/tailwind.css";
</style>
