<template>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <div class="flex justify-center">
        <h2 class="text-3xl font-bold mb-4 text-black">
          ¡Agrega un nuevo usuario!
        </h2>
      </div>
      <br />

      <br />
      <div class="flex justify-center items-center mb-2 mr-5">
        <label for="Nombre" class="md:mr-20 text-right text-2xl w-14"
          >Nombre:</label
        >
        <input
          type="text"
          id="Nombre"
          v-model="Nombre"
          class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-1/4 text-xl"
          :class="{
            'border-green-600': true,
          }"
        />
      </div>
      <br />

      <div class="flex justify-center items-center mb-2 mr-5">
        <label for="Telefono" class="md:mr-20 text-right text-2xl w-14"
          >Teléfono:</label
        >
        <input
          type="tel"
          id="Telefono"
          v-model="Telefono"
          class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-1/4 text-xl"
          :class="{
            'border-green-600': true,
          }"
        />
      </div>
      <br />

      <div class="flex justify-center items-center mb-2 mr-5">
        <label for="Contrasena" class="md:mr-20 text-right text-2xl w-14"
          >Contraseña:</label
        >
        <input
          type="text"
          id="Contrasena"
          v-model="Contrasena"
          class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-1/4 text-xl"
          :class="{
            'border-green-600': true,
          }"
        />
      </div>
      <br />

      <div class="flex justify-center items-center mb-2 mr-5">
        <label for="Usuario" class="md:mr-20 text-right text-2xl w-14"
          >Usuario:</label
        >
        <select
          v-model="Usuario"
          id="Usuario"
          class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-1/4 text-xl"
          :class="{
            'border-green-600': true,
          }"
        >
          <option value="Client">Cliente</option>
          <option value="Worker">Trabajador</option>
          <option value="Admin">Administrador</option>
        </select>
      </div>
      <br />

      <br />
      <div class="flex justify-center items-center mb-2 mr-5">
        <button @click="volver" class="btn rojo mr-8">Volver</button>
        <button @click="registrarUsuario" class="btn verde">Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios  from "axios";
export default {
  name: "UsuariosComp",
  methods: {
    volver() {
      router.push("/usuarios");
    },
    registrarUsuario() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .post(`${this.$ruta.value}/register`, {
          name: this.Nombre,
          password: this.Contrasena,
          user_type: this.Usuario,
          phone: this.Telefono,
          
        },config)
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
        window.history.back();
    }
  },
};
</script>

<style scoped>
@import "../assets/tailwind.css";
</style>
