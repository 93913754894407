<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-center items-start h-screen pt-8">
      <div class="w-1/3 ml-4">
        <h2 class="text-3xl font-bold mb-2 text-black-600 mt-6">Detalles del pedido</h2>
        <div class="mb-7 flex items-center mt-10">
          <label for="nombre" class="text-xl font-bold text-green-600 mr-2">Nombre:</label>
          <p id="nombre" class="text-xl text-black">{{ pedido.nombre }}</p>
        </div>
        <div class="mb-7 flex items-center">
          <label for="direccion" class="text-xl font-bold text-green-600 mr-2">Dirección:</label>
          <p id="direccion" class="text-xl text-black">{{ pedido.direccion }}</p>
        </div>
        <div class="mb-7 flex items-center">
          <label for="fecha" class="text-xl font-bold text-green-600 mr-2">Fecha:</label>
          <p id="fecha" class="text-xl text-black">{{ pedido.fecha }}</p>
        </div>

        <div class="mb-7">
          <label for="estado" class="text-xl font-bold text-green-600">Estado:</label>
          <select id="estado" v-model="estado" class="text-lg text-black border-2 border-green-600 rounded-md ml-6 w-40">
            <option value="0">Pendiente</option>
            <option value="1">Entregado</option>
          </select>
        </div>
        <div class="mb-7">
          <label for="entrega" class="text-xl font-bold text-green-600">Entrega:</label>
          <select id="entrega" class="text-lg text-black border-2 border-green-600 rounded-md ml-4 w-40">
            <option value="pendiente">Domicilio</option>
          </select>
        </div>
        <div class="flex items-center mt-10">
          <button @click="changeState" class="btn verde mr-8">Aceptar</button>
          <button @click="goBack" class="btn rojo">Volver</button>
        </div>
      </div>
      <div class="w-2/4 lg:mr-12 md:mr-4 mr-2 overflow-x-auto">
        <table class="w-full border-green-600 mt-8">
          <thead class="bg-custom-green">
            <tr class="bg-custom-green">
              <th class="py-3 px-3 text-xl">Nombre</th>
              <th class="py-3 px-3 text-xl">Cantidad</th>
              <th class="py-3 px-3 text-xl">Precio</th>
            </tr>
          </thead>
          <tbody class="bg-custom-green">
            <tr v-for="(producto, index) in pedido.productos" :key="index">
              <td class="py-3 px-3 bg-white text-xl text-center">{{ producto.nombre }}</td>
              <td class="py-3 px-3 bg-white text-xl text-center">{{ producto.cantidad }} Kg</td>
              <td class="py-3 px-3 bg-white text-xl text-center">${{ producto.precio }}</td>
            </tr>
            <tr>
              <td
                  colspan="1" style="border-color: transparent; border: none; background-color: white;"
                ></td>
              <td class="py-3 px-3 text-xl font-bold border-2 bg-custom-green text-center" colspan="1">
                Total:
              </td>
              <td class="bg-white text-xl border-2 border-green-600 margin text-center" colspan="2">
                $ {{ pedido.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "DetallesPedido",
  components: {
    Spinner
  },
  data() {
    return {
      pedido: [],
      estado: '0',
      loading: true
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/showOrder/${this.$id_selected.value}`, config)
      .then((response) => {
        this.pedido = response.data.data;
        this.estado = response.data.data.estado
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    goBack() {
      window.history.back();
    },
    changeState() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .put(`${this.$ruta.value}/changeStateOrder`,
          {
            id: this.$id_selected.value,
            state: this.estado
          }
          , config)
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
    }
  },
};
</script>

<style scoped>
table th,
table td {
  border: 2px solid #009929;
}
@import "../assets/tailwind.css";
</style>
