<template>
  <div>
    <Perfil/>
  </div>
</template>

<script>
import Perfil from "../components/PerfilComp.vue";

export default {
  name: "PerfilView",
  components: {
    Perfil,
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
