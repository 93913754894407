<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex items-center mt-10" style="margin-left: 25%;">
    <img :src="producto_seleccionado.imagen" class="w-13 h-14 ml-0" />
    <h2 class="text-3xl font-bold ml-3 text-black">{{ producto_seleccionado.nombre }}</h2>
  </div>

  <div class="max-w-screen-lg mx-auto">
    <div class="flex flex-col items-center justify-center mt-5">
      <div class="flex sm:flex-row justify-center mb-4 mr-5">
        <div class="flex flex-col w-full sm:w-1/2 sm:mr-8">
          <div class="flex items-center mb-4">
            <label for="Unidad" class="md:mr-3 text-right w-24 text-lg">Unidad:</label>
            <select
              v-model="Unidad"
              id="Unidad"
              class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': true,
              }"
            >
              <option value="Kilogramo">Kilogramo</option>
            </select>
          </div>
          <div class="flex items-center mb-4">
            <label for="Cantidad" class="md:mr-3 text-right ml-4 sm:ml-1 text-lg"
              >Cantidad:</label
            >
            <input
              type="text"
              id="Cantidad"
              v-model="Cantidad"
              :disabled="checkCantidad"
              class="border rounded-md px-2 py-1 ml-2 text-sm sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': !checkCantidad,
                'border-gray-400': checkCantidad,
                'bg-gray-200': checkCantidad,
              }"
              @input="restrictToNumbers"
            readonly/>
            <input
              type="checkbox"
              id="checkCantidad"
              class="hidden"
              v-model="checkCantidad"
              @change="calculate"
            />
            <label for="checkCantidad" class="cursor-pointer">
              <div
                class="w-6 h-6 border flex items-center justify-center rounded-sm border-green-600 ml-1"
                :class="{ 'border-green-600': checkCantidad }"
              >
                <svg
                  class="w-6 h-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  v-if="checkCantidad"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
                <svg
                  class="w-6 h-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  v-else
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              </div>
            </label>
          </div>
          <div class="flex items-center mb-4">
            <label for="Precio" class="md:mr-3 text-right w-24 text-lg">Precio:</label>
            <input
              type="text"
              id="Precio"
              @focus="onFocus"
              v-model="Precio"
              class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': true,
              }"
              disabled 
               @input="restrictToNumbers"
            />
          </div>
          <div class="flex items-center mb-4">
            <label for="Subtotal" class="md:mr-3 text-right w-24 text-lg">Subtotal</label>
            <input
              type="text"
              id="Subtotal"
              v-model="Subtotal"
              class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': true,
              }"
              @input="restrictToNumbers"
              readonly
            />
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 sm:ml-8">
          <div class="flex items-center mb-4">
            <label for="CantidadCajas" class="md:mr-3 text-right w-24 text-lg"
              >Cantidad de Cajas:</label
            >
            <input
              type="text"
              @focus="onFocus"
              id="CantidadCajas"
              v-model="CantidadCajas"
              :disabled="!checkCantidad"
              class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': checkCantidad,
                'border-gray-400': !checkCantidad,
                'bg-gray-200': !checkCantidad,
              }"
              @input="restrictToNumbers"
            />
          </div>
          <div class="flex items-center mb-4">
            <label for="PesoCajas" class="md:mr-3 text-right w-24 text-lg"
              >Peso de Cajas:</label
            >
            <input
              type="text"
              id="PesoCajas"
              @focus="onFocus"
              v-model="PesoCajas"
              :disabled="!checkCantidad"
              class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': checkCantidad,
                'border-gray-400': !checkCantidad,
                'bg-gray-200': !checkCantidad,
              }"
              @input="restrictToNumbers"
            />
          </div>
          <div class="flex items-center mb-2 mr-5">
            <label for="Total" class="md:mr-3 text-right w-24 text-lg">Total:</label>
            <input
              type="text"
              id="Total"
              @focus="onFocus"
              v-model="Total"
              :disabled="!checkCantidad"
              class="border rounded-md px-2 py-1 ml-2 sm:rounded-lg sm:w-full w-3/5"
              :class="{
                'border-green-600': checkCantidad,
                'border-gray-400': !checkCantidad,
                'bg-gray-200': !checkCantidad,
              }"
              @input="restrictToNumbers"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-center items-center w-full mt-2 mb-8 mx-auto"
      >
        <div class="mr-0 sm:mr-10 mb-4 sm:mb-0" style="margin-right: 13%;">
          <Teclado @input-updated="updateCantidad"/>
        </div>
        <div class="flex flex-col items-center" style="margin-right: 5%;">
          <button @click="agregar" class="btn verde mb-2 flex justify-between items-center">
            <span class="mr-1">Agregar al carrito</span>
            <img
              src="~@/assets/Rectangle.png"
              alt="Icono de carrito"
              class="w-4 h-4 ml-1" 
            />
          </button>

          <button
            @click="volver"
            class="btn rojo mb-2 flex justify-between items-center"
          >
            <span class="mr-1">Volver</span>
            <img
              src="~@/assets/exit-bold.png"
              alt="Icono de carrito"
              class="w-4 h-4 ml-1"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Teclado from "@/components/TecladoComp.vue";
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  props: {
    producto: {
      type: Object,
      required: true,
    },
  },
  components: {
    Teclado,
    Spinner
  },
  mounted(){
    this.sucursal = localStorage.getItem("sucursal");
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/article/${this.$id_selected.value}`, config)
      .then((response) => {
        this.producto_seleccionado=response.data;
        this.Precio = this.producto_seleccionado.precio;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  created() {
    // Llamar a la función periódica cuando se crea el componente
    this.iniciarLectura();
  },
  methods: {
    restrictToNumbers(event) {
    // Filtrar el contenido para permitir solo números
    event.target.value = event.target.value.replace(/[^0-9.]/g, '');
  },

    iniciarLectura() {
      this.intervalId = setInterval(() => {
        if(this.$route.name === "AgregarProductoView" && !this.checkCantidad){
          this.lectura();
        }          
      }, 2000);
    },
    lectura() {
      axios
      .get(`${this.$ruta.value}/getPeso/${this.sucursal}`)
      .then((response) => {
        this.Cantidad=response.data.peso;
        this.calculate();
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
        
      });
    },
    volver() {
      clearInterval(this.intervalId);
      router.push("/productos");
    },
    calculate(){
      if(!this.checkCantidad){
        this.Subtotal = (this.Cantidad * this.Precio).toFixed(2);
      }else{
        const pesoCajas = (this.PesoCajas * this.CantidadCajas);
        if(this.Total <= pesoCajas || pesoCajas === 0){
          this.Subtotal=(0).toFixed(2);
          return
        }
        this.Subtotal = (this.Precio * (this.Total - (pesoCajas))).toFixed(2);
      }
    },
    onFocus(event){
      const inputId = event.target.id;
      switch (inputId) {
        case "Precio":
          this.campo_seleccionado=1;
          break;
        case "CantidadCajas":
        this.campo_seleccionado=2;
          break;
        case "PesoCajas":
        this.campo_seleccionado=3;
          break;
        case "Total":
        this.campo_seleccionado=4;
          break;
      }
    },
    updateCantidad(value) {
      switch(this.campo_seleccionado){
        case 1:
          this.Precio = value;
          this.calculate();
          break;
        case 2:
          this.CantidadCajas = value;
          this.calculate();
          break;
        case 3:
          this.PesoCajas = value;
          this.calculate();
          break;
        case 4:
          this.Total = value;
          this.calculate();
          break;
      }
    },
    agregar(){
      this.Salir = true;
      if (this.Subtotal <= 0) {
        alert('¡Al parecer faltan algunos datos!');
        return;
      }
      if (!this.checkCantidad) {
        this.Peso = this.Cantidad;
      } else {
        this.Peso = this.Total - (this.PesoCajas * this.CantidadCajas);
      }
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios.post(`${this.$ruta.value}/addArticle`, {
        price: this.Subtotal,
        amount: this.Peso,
        price_unit: this.Precio,
        shoping_id: localStorage.getItem('shoping_id'),
        article_id: this.$id_selected.value,
        branch_id: this.sucursal,
      }, config)
      .then(() => {
        if (this.Salir) {
          router.push('/productos');
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
        if (error.response && (error.response.status === 401 || error.response.status === 422)) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
        this.Salir = false; // Establecer Salir en falso si hay un error
      });
    },
  },
  data() {
    return {
      Unidad: "Kilogramo",
      Cantidad: "",
      Precio: "",
      CantidadCajas: "",
      PesoCajas: "",
      Total: "",
      Subtotal: "",
      checkCantidad: false,
      campo_seleccionado:0,
      producto_seleccionado:[],
      intervalId:"",
      sucursal:0,
      Peso:0,
      Salir:true,
      loading: true
    };
  },
};
</script>
