<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-center items-start h-screen">
      <div class="flex flex-col mt-24">
        <h2 class="text-3xl font-bold mb-6 text-black ml-4">Mi Perfil</h2>
        <div class="flex justify-between items-start w-70 max-w-screen-xl mt-4 text-green-500">
          <div class="flex flex-col ml-4 mt-4">
            <div class="flex flex-row mb-8">
              <label for="nombre" class="text-xl text-green-600 font-bold">Nombre:</label>
              <p id="nombre" class="text-xl text-black ml-2">{{ this.nombre }}</p>
            </div>
            <div class="flex flex-row mb-8">
              <label for="telefono" class="text-xl text-green-600 font-bold">Telefono:</label>
              <p id="telefono" class="text-xl text-black ml-2">{{ this.telefono }}</p>
            </div>
            <div class="flex flex-row">
              <label for="fecha" class="text-xl text-green-600 font-bold">Fecha:</label>
              <p id="fecha" class="text-xl text-black ml-2">{{ this.fecha }}</p>
            </div>
          </div>
          <div class="ml-10 flex items-center mb-4">
            <img src="@/assets/foto-perfil2.png" class="w-48 h-48 rounded-full" alt="Imagen de perfil" />
          </div>
        </div>

        <div class="ml-10 flex flex-col md:flex-row items-center mt-4 mr-10">
          <button v-if="user_type == 'Admin' || user_type == 'Client' || user_type == 'Worker'"
            class="btn amarillo mb-2 md:mb-0 md:mr-4 mr-6" @click="redirigirContraseña()">
            Cambiar contraseña
          </button>

          <button v-if="user_type == 'Worker'" class="btn rojo mb-2 md:mb-0 md:mr-4 ml-6" @click="redirigirGastos()">
            Registrar gastos
          </button>

          <button v-if="user_type == 'Worker'" class="btn verde ml-6" @click="redirigirCorteCaja()">
            Corte de caja
          </button>

          <button v-if="user_type == 'Admin' || user_type == 'Client'" class="btn rojo mb-2 md:mb-0 md:mr-4 "
            @click="cerrarSesion()">
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  components: {
    Spinner
  },
  data() {
    return {
      nombre: "Martin Lopez Mendez",
      telefono: "Ciudad Valles",
      fecha: "26/03/2024",
      user_type: "",
      loading: true
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };

    const fecha = new Date();
    const day = String(fecha.getDate()).padStart(2, '0');
    const month = String(fecha.getMonth() + 1).padStart(2, '0'); // El mes se cuenta desde 0
    const year = fecha.getFullYear();
    this.fecha = `${day}/${month}/${year}`;
    axios
      .get(`${this.$ruta.value}/user`, config)
      .then((response) => {
        this.nombre = response.data.name;
        this.telefono = response.data.phone;

      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
    this.user_type = localStorage.getItem("userType");
  },
  methods: {
    redirigirContraseña() {
      router.push("/actualizarcontraseña");
    },
    redirigirGastos() {
      router.push("registragastos");
    },
    redirigirCorteCaja() {
      router.push("/cortecaja");
    },
    cerrarSesion() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .get(`${this.$ruta.value}/logout`, config)
        .then((response) => {
          alert(response.data.message);
          localStorage.removeItem('userType');
          localStorage.removeItem('logged');
          localStorage.removeItem('id');
          localStorage.removeItem('Token');
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
    },
  },
};
</script>

<style scoped>
@import "../assets/tailwind.css";
</style>
