<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <h2 class="text-3xl font-bold mb-4 text-black">Detalles del turno</h2>
      <br />

      <div class="table-container">
        <table class="bg-custom-green w-full border-2 rounded-lg">
          <thead>
            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-xl">Nombre:</th>
              <td class="bg-white text-center text-xl" colspan="2">
                {{info_turnos.nombre}}
              </td>
              <th class="py-4 px-6 text-xl">Sucursal:</th>
              <td class="bg-white text-center text-xl" colspan="2">
                {{info_turnos.sucursal}}
              </td>
            </tr>

            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-xl">Fecha:</th>
              <td class="bg-white text-center text-xl">{{info_turnos.fecha}}</td>
              <th class="py-4 px-6 text-xl">Inicio:</th>
              <td class="bg-white text-center text-xl">{{info_turnos.inicio}}</td>
              <th class="py-4 px-6 text-xl">Cierre:</th>
              <td class="bg-white text-center text-xl">{{ info_turnos.fin }}</td>
            </tr>

            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-xl">Gastos:</th>
              <td class="bg-white text-center text-xl">${{info_turnos.gastos}}</td>
              <th class="py-4 px-6 text-xl">Ventas:</th>
              <td class="bg-white text-center text-xl">${{info_turnos.ventas}}</td>
              <th class="py-4 px-6 text-xl">Total:</th>
              <td class="bg-white text-center text-xl">${{info_turnos.total}}</td>
            </tr>
          </thead>
        </table>
      </div>
      <br />

      <br />
      <div class="table-container">
        <table class="bg-custom-green w-full border-2 rounded-lg mx-auto">
          <thead>
            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-xl">ID Venta</th>
              <th class="py-4 px-6 text-xl">Hora</th>
              <th class="py-4 px-6 text-xl">Total</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(deTurno) in info_turnos.lista_ventas" :key="deTurno.id" @click="mostrarTurn(deTurno.id)" class="cursor-pointer">
              <td class="bg-white text-center text-xl">{{ deTurno.id }}</td>
              <td class="bg-white text-center text-xl">{{ deTurno.hora }}</td>
              <td class="bg-white text-center text-xl">${{ deTurno.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />

      <div class="flex justify-end mt-2">
        <button @click="goBack" class="btn verde">Aceptar</button>
      </div>
      <br />
    </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "DetallesTurnoComp",
  components: {
    Spinner
  },
  data() {
    return {
      info_turnos: [],
      loading: true
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/getTurn/${this.$id_selected.value}`, config)
      .then((response) => {
        console.log(response.data);
        this.info_turnos = response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    mostrarTurn(id) {
      this.$id_selected.value2 = id;
      router.push("/detalleVenta");
    },
    goBack() {
      window.history.back();
    }
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
  /* Color con 40% de opacidad */
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}
</style>
