<template>
      <Detallepedido />
  </template>
  
  <script>
  import Detallepedido from "../components/DetallesPedidosComp.vue";
  
  export default {
    name: "DetallePedidosView",
    components: {
      Detallepedido,
    },
  };
  </script>