<template>
  <Login
    @update:phone="inputPhone"
    @update:password="inputPassword"
    @ingresar="ingresar"
    :errorMsg="errorMsg"
  />
</template>

<script>
import Login from "../components/LoginComp.vue";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "LoginView",
  components: {
    Login,
  },
  data() {
    return {
      phone: "",
      password: "",
      errorMsg: "",
      claveSecreta: "123456789",
    };
  },
  mounted() {
    if (localStorage.getItem("logged") === "true") {
      if (this.$userType.value === "Admin") {
        this.$router.push("/menuproductos");
      } else if (this.$userType.value === "Client") {
        this.$router.push("/productos");
      } else if (this.$userType.value === "Worker") {
        if (
          localStorage.getItem("sucursal") == "false" ||
          !localStorage.getItem("sucursal")
        ) {
          this.$router.push("/selecSucursal");
        } else {
          this.$router.push("/productos");
        }
      }
    }
  },

  methods: {
    inputPhone(value) {
      this.phone = value;
    },
    inputPassword(value) {
      this.password = value;
    },

    ingresar() {
      axios
        .post(`${this.$ruta.value}/login`, {
          phone: this.phone,
          password: this.password,
        })
        .then((response) => {
          const encryptedToken = CryptoJS.AES.encrypt(
            response.data.access_token,
            this.claveSecreta
          ).toString();
          console.log(response.data.access_token);
          this.$token.value = response.data.access_token;
          this.$userType.value = response.data.user.user_type;
          localStorage.setItem("Token", encryptedToken);
          localStorage.setItem("userType", response.data.user.user_type);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("logged", true);

          if (
            response.data.user.user_type === "Admin" ||
            response.data.user.user_type === "Client"
          ) {
            window.location.reload();
          } else {
            if (localStorage.getItem("sucursal") == "false") {
              this.$router.push("/selecSucursal");
            } else {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
    },
  },
};
</script>
