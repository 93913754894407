<template>
  <Cuenta></Cuenta>

</template>

<script>
import Cuenta from "../components/DetalleCuentaComp.vue";

export default {
  name: "CuentaView",
  components: {
    Cuenta,
    
  },
};
</script>