<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-center items-start h-screen">
      <div class="w-full max-w-screen-xl mt-10">
        <h2 class="text-3xl font-bold mb-4 text-black">Mas información</h2>
        <br />

        <div class="overflow-x-auto">
          <div class="table-container">
            <table class="w-full md:w-auto bg-custom-green">
              <tr>
                <th
                  class="w-1/5 bg-custom-green text-xl border-2 border-green-600"
                >
                  Id transacción
                </th>
                <th
                  class="w-1/5 text-xl bg-white border-2 border-green-600 text-center font-normal"
                >
                  {{ this.$id_selected.value2 }}
                </th>
                <th
                  class="w-1/5 bg-white border-2 border-white text-center md:border-r"
                ></th>
                <th class="bg-white text-center md:border-r"></th>
                <th
                  class="bg-custom-green w-1/5 text-xl border-2 border-green-600"
                >
                  Fecha
                </th>
                <th
                  class="w-1/5 bg-white text-xl border-2 border-green-600 rounded-r-lg text-center font-normal"
                >
                  {{ fecha }}
                </th>
              </tr>
            </table>
          </div>
        </div>

        <div class="table-container">
          <table class="bg-custom-green w-full border-green-600 mt-8">
            <thead>
              <tr class="bg-custom-green">
                <th
                  class="py-4 px-6 text-xl border-2 border-green-600 text-center td-con-borde-derecho-columna"
                >
                  Producto
                </th>
                <th
                  class="py-4 px-6 text-xl border-2 border-green-600 text-center td-con-borde-derecho-columna"
                >
                  Cantidad
                </th>
                <th
                  class="py-4 px-6 text-xl border-2 border-green-600 text-center td-con-borde-derecho-columna"
                >
                  Precio Unitario
                </th>
                <th
                  class="py-4 px-6 text-xl text-center border-t-2 border-b-2 border-green-600"
                >
                  Sub Total
                </th>
                <th
                  class="py-4 px-6 text-2xl border-t-2 border-b-2 border-r-2 border-green-600 text-center"
                ></th>
              </tr>
            </thead>

            <tbody>
              <!-- Aquí se iteran las filas existentes -->
              <tr v-for="(producto, index) in productos" :key="index">
                <td
                  class="py-4 bg-white text-xl border-2 border-green-600 td-con-borde-derecho-columna text-center "
                >
                  {{ producto.nombre }}
                </td>
                <td
                  class="py-4 bg-white text-xl border-2 border-green-600 text-center td-con-borde-derecho"
                >
                  {{ producto.cantidad }} Kg
                </td>
                <td
                  class="py-4 bg-white text-xl border-2 border-green-600 text-center td-con-borde-derecho"
                >
                  $ {{ producto.precio_unitario }}
                </td>
                <td class="py-4 text-xl text-center bg-white">
                  $ {{ producto.subtotal }}
                </td>

                <td
                  class="text-2x text-center bg-white border-r-2 border-green-600"
                  v-if="user_type === 'Admin'"
                >
                  <button
                    @click="eliminar(producto.id)"
                    class="mr-6 p-5 rounded-full bg-red-500 bg-opacity-50 hover:bg-red-500 focus:outline-none focus:bg-red-500 items-center"
                    style="width: 2rem; height: 2rem"
                  >
                    <div
                      class="flex justify-center items-center"
                      style="width: 100%; height: 100%"
                    >
                      <i class="material-symbols-outlined text-2xl">delete</i>
                    </div>
                  </button>
                </td>
              </tr>
              <tr class="bg-custom-green">
                <td
                  class="bg-white border-2 border-white text-center border-r"
                  colspan="2"
                ></td>
                <td
                  class="py-4 px-6 text-xl font-bold border-2 border-green-600 text-center"
                  colspan="1"
                >
                  Total:
                </td>
                <td
                  class="bg-white text-xl border-2 border-green-600 margin"
                  colspan="2"
                >
                  $ {{ total }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 10px"
        >
          <button
            @click="goBack"
            class="btn verde text-lg"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "CarritoComp",
  components: {
    Spinner,
  },
  data() {
    return {
      productos: [],
      total: 0,
      fecha: "",
      loading: true,
      user_type: null,
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    axios
      .get(
        `${this.$ruta.value}/showDebtItems/${this.$id_selected.value2}`,
        config
      )
      .then((response) => {
        this.productos = response.data.data.productos;
        this.total = response.data.data.total;
        this.fecha = response.data.data.fecha;
      })
      .catch((error) => {
        alert(error.response.data.message);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
    this.user_type = localStorage.getItem("userType");
  },
  methods: {
    goBack() {
      window.history.back();
    },
    eliminar($id) {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      axios
        .post(
          `${this.$ruta.value}/deleteItem`,
          {
            item_id: $id,
            transaction_id: this.$id_selected.value2,
          },
          config
        )
        .then((response) => {
          alert(response.data.message);
          window.history.back();
        })
        .catch((error) => {
          alert(error.response.data.message);
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
    },
  },
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}
.td-con-borde-derecho {
  border-right: 2px solid rgb(255, 255, 255);
  
}
.td-con-borde-derecho-columna {
  border-right: 2px solid transparent;

}
.margin {
  padding-left: 7.8%;

}
</style>
