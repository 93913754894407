<template>
    <VerTransaccion />
    <asignar />
  </template>
  
  <script>
  import VerTransaccion from "../components/VerTransaccionComp.vue";
  
  export default {
    name: "VerTransaccionView",
    components: {
      VerTransaccion,
    },
  };
  </script>
  