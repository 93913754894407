<template>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <h2 class="text-3xl font-bold mb-4 text-black">Carrito de compra</h2>
      <br />
      <div class="table-container">
        <table class="bg-custom-green w-full border-2 rounded-lg">
          <thead>
            <tr class="bg-custom-green">
              <th class="py-4 px-6 text-xl td-con-borde-derecho-columna">
                Producto
              </th>
              <th class="py-4 px-6 text-xl td-con-borde-derecho-columna">
                Cantidad
              </th>
              <th class="py-4 px-6 text-xl td-con-borde-derecho-columna">
                Precio
              </th>
              <th class="py-4 px-6 text-xl td-con-borde-derecho-columna">
                Sub Total
              </th>
              <th class="py-4 px-6 text-xl"></th>
            </tr>
          </thead>

          <tbody>
            <!-- Aquí se iteran las filas existentes -->
            <tr v-for="(producto, index) in productos" :key="index">
              <td class="bg-white text-xl td-con-borde-derecho">
                <div style="display: flex; align-items: center">
                  <img
                    :src="producto.imagen"
                    style="max-width: 50px; max-height: 50px; margin-right: 10px"
                  />
                  <span class="text-xl">{{ producto.nombre }}</span>
                </div>
              </td>

              <td class="bg-white text-xl td-con-borde-derecho">
                {{ producto.cantidad }} Kg
              </td>
              <td class="bg-white text-xl td-con-borde-derecho">
                $ {{ producto.precio_unitario }}
              </td>
              <td class="bg-white text-xl td-con-borde-derecho">
                $ {{ producto.subtotal }}
              </td>
              <td class="bg-white text-center alineacion">
                <button
                  class="mr-6 p-5 rounded-full bg-red-500 bg-opacity-50 hover:bg-red-500 focus:outline-none focus:bg-red-500 items-center"
                  style="width: 3rem; height: 3rem"
                >
                  <div
                    class="flex justify-center items-center"
                    style="width: 100%; height: 100%"
                    @click="eliminar(producto.id)"
                  >
                    <i class="material-symbols-outlined text-2xl">delete</i>
                  </div>
                </button>
              </td>
            </tr>
            <tr class="bg-custom-green">
              <td class="bg-white no-border" colspan="2"></td>
              <td colspan="1" class="py-4 px-6 text-xl margen font-bold">
                Total:
              </td>
              <td colspan="2" class="bg-white text-xl margen">${{ total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />

      <div
        style="display: flex; justify-content: flex-end; margin-bottom: 10px"
      >
        <button
          @click="redirigir()"
          class="btn amarillo text-lg"
          style="margin-right: 65px"
        >
          Asignar pedido
        </button>
        <button @click="comprar()" class="btn verde text-lg" style="margin-right: 55px">
          Finalizar compra
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
export default {
  name: "CarritoComp",
  data() {
    return {
      productos: [],
      total:0
    };
  },
  methods: {
    comprar(){
      if (this.productos.length === 0) {
        alert('Aún no se han agregado productos');
        return;
      }

      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };

      axios.post(`${this.$ruta.value}/createSales`, {
        price: this.total,
        branch_id: localStorage.getItem('sucursal'),
        shoping_id: localStorage.getItem('shoping_id')
      }, config)
      .then(() => {
        alert('Venta generada exitosamente!!');
        this.newShopingCar();
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
        if (error.response && (error.response.status === 401 || error.response.status === 422)) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
        //this.mostrarError = true;
        }
    });
    },
    newShopingCar(){
      const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/createShopingCar`,config)
      .then((response)=>{
        localStorage.setItem("shoping_id",response.data);
        router.push("/productos");
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
    },
    redirigir() {
      if(this.productos.length === 0){
        alert('Aún no se han agredo productos');
        return;
      }
      this.$id_selected.value = this.total;
      router.push("/asignarpedido");
    },
    eliminar(id){
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .post(`${this.$ruta.value}/deleteArticleSelect`,
      {
        id: id,
        branch_id: localStorage.getItem('sucursal')
      }
      ,config)
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(
        this.obtenerLista()
      );
      
    },
    obtenerLista(){
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .get(`${this.$ruta.value}/selectSalesItems/${localStorage.getItem('shoping_id')}`
        ,config)
        .then((response) => {
         this.total = response.data.data.total,
         this.productos = response.data.data.productos
        })
       .catch((error) => {
          console.error("Error al enviar el mensaje:", error);
         if (
           error.response &&
           (error.response.status === 401 || error.response.status === 422)
         ) {
           this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
         } else {
           //this.mostrarError = true;
         }
       })
       .finally(() => {
        this.loading = false;
      });
    }
  },
  mounted(){
    this.obtenerLista();
  }
};
</script>

<style scoped>
.table-container {
  overflow-x: auto;
}
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}

.td-con-borde-derecho {
  border-right: 2px solid rgb(255, 255, 255);
  text-align: left;
  padding-left: 3%;
}

.td-con-borde-derecho-columna {
  border-right: 2px solid transparent;
  text-align: left;
  padding-left: 3%;
}

.margen {
  text-align: left;
  padding-left: 3%;
}

.alineacion {
  text-align: right;
  padding-right: 6%;
  align-items: flex;
}

.bg-green-400 {
  background-color: rgba(92, 203, 95, 0.4);
  /* Color con 40% de opacidad */
}

.no-border {
  border-left: solid white;
  border-bottom: solid white;
}
</style>
