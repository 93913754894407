<template>
    <Usuarios></Usuarios>
  
  </template>
  
  <script>
  import Usuarios from "../components/UsuariosComp.vue";
  
  export default {
    name: "UsuariosView",
    components: {
      Usuarios,
      
    },
  };
  </script>