<template>
  <NuevaSucursal :tituloProp="titulo" :camposProp="campos" />
</template>

<script>
import NuevaSucursal from "../components/NuevaSucursalComp.vue";

export default {
  name: "NuevaSucursalView",
  components: {
    NuevaSucursal,
  },
  data() {
    return {
      titulo: "Nueva sucursal",
      campos: {
        nombre: {
          id: "Nombre",
          texto: "Ingrese el nombre",
          type: "text"
        },
        direccion: {
          id: "Direccion",
          texto: "Ingrese la direccion",
          type: "text"
        }
      }
    };
  },
};
</script>
