<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-center items-start h-screen">
      <div class="w-full max-w-screen-xl mt-10">
        <h2 class="text-3xl font-bold mb-4 text-black">
          Detalles de la cuenta
        </h2>
        <br />

        <div class="overflow-auto">
          <table class="bg-custom-green w-full border-2 rounded-lg">
            <thead>
              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl">Nombre:</th>
                <td class="bg-white text-center text-xl" colspan="3">
                  {{ cuenta.nombre }}
                </td>
              </tr>

              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl">Deuda:</th>
                <td class="bg-white text-center text-xl">
                  $ {{ cuenta.deuda }}
                </td>
                <th class="py-4 px-6 text-xl">Abono:</th>
                <td class="bg-white text-center text-xl">
                  $ {{ cuenta.abono }}
                </td>
              </tr>

              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl">Pago minimo:</th>
                <td class="bg-white text-center text-xl">
                  $ {{ cuenta.pago_minimo }}
                </td>
                <th class="py-4 px-6 text-xl">Límite:</th>
                <td class="bg-white text-center text-xl">
                  $ {{ cuenta.limite }}
                </td>
              </tr>
            </thead>
          </table>
        </div>
        <br />

        <br />
        <div class="overflow-auto">
          <table class="bg-custom-green w-full border-2 rounded-lg mx-auto">
            <thead>
              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl">ID</th>
                <th class="py-4 px-6 text-xl">Fecha</th>
                <th class="py-4 px-6 text-xl">Transacción</th>
                <th class="py-4 px-6 text-xl">Cantidad</th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="operacion in cuenta.operaciones"
                :key="operacion.id"
                @click="showOrder(operacion.id, operacion.transaccion)"
                :class="{ 'cursor-pointer': operacion.transaccion === 'Deuda' }"
              >
                <td class="bg-white text-center text-xl">{{ operacion.id }}</td>
                <td class="bg-white text-center text-xl">
                  {{ operacion.fecha }}
                </td>
                <td class="bg-white text-center text-xl">
                  {{ operacion.transaccion }}
                </td>
                <td class="bg-white text-center text-xl">
                  $ {{ operacion.cantidad }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <div
          class="ml-10 flex flex-col md:flex-row items-center mt-4 justify-end"
        >
          <button
            v-if="user_type !== 'Client'"
            @click="abonar()"
            class="btn amarillo mb-2 md:mb-0 md:mr-6"
          >
            Abonar
          </button>
          <button @click="goBack" class="btn verde mb-2 md:mb-0 md:mr-2">
            Aceptar
          </button>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "CuentaComp",
  components: {
    Spinner,
  },
  data() {
    return {
      cuenta: "",
      loading: true,
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    if (localStorage.getItem("userType") == "Client") {
      axios
        .get(`${this.$ruta.value}/getDebtId`, config)
        .then((response) => {
          this.cuenta = response.data.data;
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      axios
        .get(
          `${this.$ruta.value}/showTransactionId/${this.$id_selected.value}`,
          config
        )
        .then((response) => {
          this.cuenta = response.data.data;
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.user_type = localStorage.getItem("userType");
  },
  methods: {
    goBack() {
      window.history.back();
    },
    showOrder($id, $transaccion) {
      if ($transaccion == "Deuda") {
        this.$id_selected.value2 = $id;
        router.push("/vertransaccion");
      }
    },
    abonar() {
      router.push("/abonar");
    },
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
  /* Color con 40% de opacidad */
}
</style>
