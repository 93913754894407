<template>
  <div class="flex flex-col items-center mt-16">
    <h2 class="text-3xl font-bold mb-6 text-black text-center">Nueva contraseña</h2>
    <div class="mt-4">
      <div class="mb-6">
        <label for="nueva-contrasena" class="text-2xl mb-3">Ingrese la nueva contraseña:</label>
      </div>
      <div>
        <input type="password" id="nueva-contrasena" v-model="nuevaContrasena"
          class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl" :class="{
            'border-green-600': true,
          }" />
      </div><br>
      <div class="mb-6">
        <label for="confirmar-contrasena" class="text-2xl mb-3">Confirme la contraseña:</label>
      </div>
      <div>
        <input type="password" id="confirmar-contrasena" v-model="confirmarContrasena"
          class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl" :class="{
            'border-green-600': true,
          }" />
      </div>
      <div class="mt-8 flex justify-between w-64 gap-x-6 ml-2">
      <button @click="volver" class="btn rojo">Volver</button>
      <button @click="guardarNuevaContrasena" class="btn verde">Aceptar</button>
    </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
export default {
  name: "NuevoComponenteContrasena",
  data() {
    return {
      nuevaContrasena: "",
      confirmarContrasena: "",
    };
  },
  methods: {
    guardarNuevaContrasena() {
      if (this.nuevaContrasena === this.confirmarContrasena) {
        const config = {
          headers: { Authorization: `Bearer ${this.$token.value}` }
        };
        axios
          .put(`${this.$ruta.value}/updatePassword`,
            {
              id: localStorage.getItem('id'),
              password: this.nuevaContrasena
            }
            , config)
          .then((response) => {
            alert(response.data.message);
            router.push('/perfil');
          })
          .catch((error) => {
            alert(error.response.data.message);
            console.error("Error al enviar el mensaje:", error);
            if (
              error.response &&
              (error.response.status === 401 || error.response.status === 422)
            ) {
              this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
            } else {
              //this.mostrarError = true;
            }
          });

      } else {
        alert("Las contraseñas no coinciden");
      }
    },
    volver() {
      router.push('/perfil');
    },
  },
};
</script>

<style scoped>
@import "../assets/tailwind.css";
</style>
