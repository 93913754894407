<template>
  <div>
    <Pedidos />
  </div>
</template>

<script>
import Pedidos from "../components/PedidosComp.vue";

export default {
  name: "PedidosView",
  components: {
    Pedidos,
  },
};
</script>
