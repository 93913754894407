<template>
  <div
    class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 m-4"
  >
    <div
      v-for="(producto, index) in productos"
      :key="index"
      class="bg-custom-green border-green-600 border-2 rounded-lg cursor-pointer mt-4 lg:h-76 md:h-76 sm:h:80"
      @click="user_type === 'Worker' ? redirigir(producto.id) : null"
    >
      <img
        :src="producto.imagen"
        :alt="producto.nombre"
        class="w-full h-64 sm:h-48 mt-8"
      />
      <div class="text-center mt-2 text-xl font-bold">$ {{ producto.precio }}</div><br>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "ProductosComp",
  props: {
    productos: {
      type: Array,
    },
  },
  data() {
    return {
      user_type: "",
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("userType");
  },
  methods: {
    redirigir(id) {
      this.$id_selected.value = id;
      router.push("/agregarproducto");
    },
  },
};
</script>
