<template>
    <DetalleVenta />
    <asignar />
  </template>
  
  <script>
  import DetalleVenta from "../components/DetalleVentaComp.vue";
  
  export default {
    name: "DetalleVentaView",
    components: {
      DetalleVenta,
    },
  };
  </script>
  