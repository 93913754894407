<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="lg:ml-20 ml-2 md:ml-10 text-3xl font-bold text-black">
        {{ Text }}
      </h2>
    </div>
    <div
      v-for="(producto, index) in productos"
      :key="index"
      class="bg-custom-green border-green-600 border-2 rounded-lg mt-8 mr-3 ml-3 h-20 relative md:ml-10 md:mr-10 lg:ml-20 lg:mr-20 cursor-pointer"
      @click="
        user_type === 'Admin'
          ? redirigir(producto.id, producto.articulo_id)
          : null
      "
    >
      <div class="flex justify-start">
        <img
          :src="producto.imagen"
          :alt="producto.nombre"
          class="w-15 h-14 mt-3 ml-5"
        />
        <div class="mt-6 ml-6 text-2xl">{{ producto.nombre }}</div>
      </div>
      <button
        v-if="user_type === 'Admin'"
        class="absolute top-0 right-0 font-bold mt-6 mr-32"
        @click.stop="redirigirTransferenica(producto.articulo_id)"
      >
        <span class="material-symbols-outlined w-5 h-5"> swap_vert </span>
      </button>

      <div class="absolute top-0 right-0 font-bold mt-6 mr-12 text-xl">
        {{ producto.cantidad }} Kg
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "ProductosComp",
  props: {
    Text: {
      type: String,
    },
    productos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      user_type: null,
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("userType");
  },
  methods: {
    redirigir($id, $articulo_id) {
      this.$id_selected.value3 = $id;
      this.$id_selected.value2 = $articulo_id;
      router.push("/actualizarcantidad");
    },
    redirigirTransferenica(articulo_id) {
      router.push({
        path: "/transferencia",
        query: { articulo_id: articulo_id },
      });
    },
  },
};
</script>

<style>
.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}
</style>
