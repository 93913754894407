<template>
  <asignar />
</template>

<script>
import asignar from "@/components/AsignarPedidoComp.vue";

export default {
  name: "CarritoView",
  components: {
    asignar,
  },
};
</script>
