<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <ListaCantidadComp :Text="Text" :productos="productos" />
  </div>
</template>

<script>
import ListaCantidadComp from "../components/ListaCantidadComp.vue";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "ListaProductosView",
  components: {
    ListaCantidadComp,
    Spinner,
  },

  data() {
    return {
      Text: "Cantidades de Producto",
      productos: [],
      loading: true,
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    axios
      .get(`${this.$ruta.value}/amount/${this.$id_selected.value}`, config)
      .then((response) => {
        this.productos = response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })

      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
