<template>
  <nav class="w-full h-20 bg-green-600 ">
     <div class="container mx-auto px-2"> 
        <div class="flex justify-between items-center">
           <img src="@/assets/Blueberry-1.png" alt="Logo" class="h-14 w-14 mt-3 hidden md:block" />
           <img src="@/assets/Orange-2.png" alt="Logo" class="h-14 w-14 mt-3 hidden md:block" />

           <img src="@/assets/Avocado-3.png" alt="Logo" class="h-14 w-14 mt-3" />
           <img src="@/assets/Orange-2.png" alt="Logo" class="h-14 w-14 mt-3" />

           <img src="@/assets/Avocado-3.png" alt="Logo" class="h-14 w-14 mt-3" />
           <img src="@/assets/Orange.png" alt="Logo" class="h-14 w-14 mt-3" />

           <img src="@/assets/Avocado-1.png" alt="Logo" class="h-14 w-14 mt-3" />
           <img src="@/assets/Orange.png" alt="Logo" class="h-14 w-14 mt-3 hidden md:block" />

           <img src="@/assets/Avocado-1.png" alt="Logo" class="h-14 w-14 mt-3 hidden md:block" />
           <img src="@/assets/Blueberry.png" alt="Logo" class="h-14 w-14 mt-3 hidden md:block" />
        </div>
     </div>
  </nav>
</template>
