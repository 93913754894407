<template>
  <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2 m-auto max-w-screen-sm mt-8">
    <router-link
      v-for="(item, index) in menu"
      :key="index"
      :to="item.route"
      class="bg-custom-green border-green-600 border-2 rounded-lg cursor-pointer sm:mt-10 sm:mx-4"
      style="height: 300px; max-width: 250px;"
    >
      <div class="flex flex-col justify-center items-center h-full">
        <img
          :src="item.image"
          class="w-30 h-30 mb-4"
        />
        <div class="text-center font-bold text-xl">{{ item.name }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          name: "Cuentas",
          image: require("@/assets/cuentas.png"),
          route: "/cuentas" 
        },
        {
          name: "Pedidos",
          image: require("@/assets/pedidos.png"),
          route: "/pedidos" 
        },
      ],
    };
  },
};
</script>
