<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <div class="flex justify-between items-center">
        <h2 class="text-3xl font-bold mb-4 text-black">Usuarios</h2>

        <button @click="ToRegister" class="btn verde">Registrar</button>
      </div>
      <br />

      <table class="bg-custom-green w-full border-2 rounded-lg">
        <thead>
          <tr class="bg-custom-green">
            <th class="py-4 px-6 text-xl td-con-borde-derecho-columna">
              Nombre
            </th>
            <th class="py-4 px-6 text-xl td-con-borde-derecho-columna">
              Usuario
            </th>
            <th class="py-4 px-6 text-xl"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="usuario in usuarios" :key="usuario.id">
            <td class="bg-white text-xl td-con-borde-derecho">
              {{ usuario.name }}
            </td>
            <td class="bg-white text-xl td-con-borde-derecho">
              {{ usuario.user_type }}
            </td>
            <td class="bg-white text-center alineacion">
              <button @click="changeState(usuario.id)"
                :class="{
                  'bg-red-500': !usuario.available,
                  'bg-green-500': usuario.available,
                }"
                class="mr-6 p-5 rounded-full bg-opacity-50 hover:bg-opacity-75 focus:outline-none focus:bg-opacity-75 items-center"
                style="width: 3rem; height: 3rem"
              >
                <div
                  class="flex justify-center items-center"
                  style="width: 100%; height: 100%"
                >
                  <i class="material-symbols-outlined text-2xl">{{
                    usuario.available ? "check" : "clear"
                  }}</i>
                </div>
              </button>

              <button
                @click="editUser(usuario.id)"
                class="mr-6 p-5 rounded-full bg-green-400 hover:bg-green-500 focus:outline-none focus:bg-green-500 items-center"
                style="width: 3rem; height: 3rem"
              >
                <div
                  class="flex justify-center items-center"
                  style="width: 100%; height: 100%"
                >
                  <i class="material-symbols-outlined text-2xl">edit</i>
                </div>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "UsuariosComp",
  components: {
    Spinner
  },
  data() {
    return {
      usuarios: [],
      loading: true
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    axios
      .get(`${this.$ruta.value}/users`, config)
      .then((response) => {
        this.usuarios = response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ToRegister() {
      router.push("/nuevousuario");
    },
    editUser(id) {
      this.$id_selected.value = id;
      router.push("/editarusuario");
    },
    changeState($id){
      const config2 = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      axios
        .get(`${this.$ruta.value}/updateAvailable/${$id}`, config2)
        .then((response) => {
          alert(response.data.message);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);
          if(
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
      },
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4); /* Color con 40% de opacidad */
}

.td-con-borde-derecho {
  border-right: 2px solid rgb(255, 255, 255);
  text-align: left;
  padding-left: 3%;
}

.td-con-borde-derecho-columna {
  border-right: 2px solid transparent;
  text-align: left;
  padding-left: 3%;
}

.alineacion {
  text-align: right;
  padding-right: 6%;
  align-items: flex;
}

.bg-green-400 {
  background-color: rgba(92, 203, 95, 0.4); /* Color con 40% de opacidad */
}
</style>
