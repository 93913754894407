<template>
  <NuevaSucursal :tituloProp="titulo" :camposProp="campos" :edicion="true"/>
</template>

<script>
import NuevaSucursal from "../components/NuevaSucursalComp.vue";

export default {
  name: "NuevaSucursalView",
  components: {
    NuevaSucursal,
  },
  data() {
    return {
      titulo: "Edite la sucursal",
      campos: {
        nombre: {
          id: "Nombre",
          texto: "Nombre:",
          type: "text"
        },
        direccion: {
          id: "Direccion",
          texto: "Dirección:",
          type: "text"
        }
      }
    };
  },
};
</script>
