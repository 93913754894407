<template>
  <div class="flex justify-center items-center mt-16">
    <div class="w-full max-w-screen-xl mt-3">
      <div class="flex justify-center">
        <h2 class="text-3xl font-bold mb-6 text-black">Edite el usuario</h2>
      </div>

      <div class="flex justify-center">
        <div class="w-full max-w-custom-width mt-5">
          <div class="flex flex-col mb-2">
            <label for="Telefono" class="text-2xl mb-3">Teléfono:</label>
            <input
              type="tel"
              id="Telefono"
              v-model="Telefono"
              class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl"
              :class="{
                'border-green-600': true,
              }"
            />
          </div>
          <br />

          <div class="flex flex-col mb-2">
            <label for="Usuario" class="md:mr-20 text-right mb-3 text-2xl w-14"
              >Usuario:</label
            >
            <select
              v-model="Usuario"
              id="Usuario"
              class="border rounded-md px-2 py-1 sm:rounded-lg w-full text-xl"
              :class="{
                'border-green-600': true,
              }"
            >
              <option value="Worker">Trabajador</option>
              <option value="Admin">Administrador</option>
            </select>
          </div>
        </div>
      </div>

      <br />
      <div class="flex justify-center items-center mt-3">
        <button @click="goBack" class="btn rojo mr-8">Volver</button>
        <button @click="editarUsuario" class="btn verde">Guardar</button>
      </div>
    </div>
  </div>
</template>

<script>
import  axios  from 'axios';
export default {
  name: "EditUsuarioComp",
  methods: {
    goBack() {
      window.history.back();
    },
    editarUsuario() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };
      axios
        .put(`${this.$ruta.value}/updateUser_type/${this.$id_selected.value}`, {
          phone: this.Telefono,
          user_type: this.Usuario,
        }, config)
        .then((response) => {
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);

          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
      window.history.back();
    }
  },
};
</script>

<style scoped>
.max-w-custom-width {
  max-width: 300px;
}
</style>
