<template>
  <div class="login-fondo">
    <div class="fixed inset-0 flex justify-center items-center">
      <div class="bg-white p-8 rounded-lg sm:w-full lg:w-1/3 md:w-4/5">
        <img
          src="@/assets/LogoJarocho2.png"
          alt="Logo"
          class="w-4/12 h-4/12 mx-auto my-auto"
        />
        <div class="text-center font-bold mt-5 text-2xl">Inicio de sesión</div>
        <input
          type="text"
          :value="localPhone"
          @input="inputPhone($event.target.value)"
          placeholder="Teléfono"
          class="block w-full border border-gray-300 rounded-md mt-4 px-3 py-2"
          @keyup.enter="ingresar"
          maxlength="10"
        />
        <input
          type="password"
          :value="password"
          @input="inputPassword($event.target.value)"
          placeholder="Contraseña"
          class="block w-full border border-gray-300 rounded-md px-3 py-2 mt-8"
          @keyup.enter="ingresar"
        />
        <p v-if="errorMsg" class="mt-5 text-center">{{ errorMsg }}</p>
        <div class="text-center mt-5">
          <button @click="ingresar" class="btn amarillo w-full">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    phone: String,
    password: String,
    errorMsg: String,
  },
  data() {
    return {
      localPhone: this.phone,
    };
  },
  methods: {
    inputPhone(value) {
      this.localPhone = value;
      this.PhoneInput();
      this.$emit("update:phone", this.localPhone);
    },
    inputPassword(value) {
      this.$emit("update:password", value);
    },
    ingresar() {
      this.$emit("ingresar");
    },
    PhoneInput() {
      this.localPhone = this.localPhone.replace(/\D/g, "");
    },
  },
};
</script>

<style scoped>
@import "@/assets/tailwind.css";
</style>
