<template>
      <div v-if="loading">
    <Spinner
      :size="{ width: '2em', height: '2em' }"
      class="style-spinner"
    />
  </div>
  <div v-else>
  <div class="flex justify-center items-start h-screen">
    <div class="w-full max-w-screen-xl mt-10">
      <div class="flex justify-between items-center">
        <h2 class="text-3xl font-bold mb-4 text-black">Sucursales</h2>
      </div>
      <br />

      <table class="bg-custom-green w-full border-2 rounded-lg">
        <thead>
          <tr class="bg-custom-green">
            <th class="py-4 px-6 text-xl td-con-borde-derecho-columna text-left">
              Nombre
            </th>
            <th class="py-4 px-6 text-xl text-left">
              Dirección
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(sucursal, index) in sucursales" :key="index" @click="redirigir(sucursal.id)" class="cursor-pointer">
            <td class="bg-white text-xl td-con-borde-derecho">
              {{ sucursal.nombre }}
            </td>
            <td class="bg-white text-xl">
              {{ sucursal.direccion }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "ListSucursalesComp",
  components: {
    Spinner
  },
  data(){
    return{
      sucursales:[],
      loading: true
    }
  },
  mounted(){
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/branch`, config)
      .then((response) => {
        this.sucursales=response.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    redirigir($id) {
      this.$id_selected.value = $id;
      router.push("/existenciaproductos");
    },
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}

.td-con-borde-derecho {
  border-right: 2px solid rgb(255, 255, 255);
  text-align: left;
  padding-left: 3%;
}

.td-con-borde-derecho-columna {
  border-right: 2px solid transparent;
  text-align: left;
  padding-left: 3%;
}

.bg-green-400 {
  background-color: rgba(92, 203, 95, 0.4); /* Color con 40% de opacidad */
}

@import "../assets/tailwind.css";
</style>
