<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-between items-start h-screen">
      <div class="w-1/2 p-5">
        <div class="w-full max-w-screen-xl">
          <h2 class="text-3xl font-bold mb-4 text-black mt-4 ml-16">Corte de caja</h2>
          <table class="w-full border-green-600 border-2 rounded-lg mt-8 ml-16">
            <thead>
              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl text-center">Hora</th>
                <th class="py-4 px-6 text-xl text-center">Id Venta</th>
                <th class="py-4 px-6 text-xl text-center">Total</th>
              </tr>
            </thead>
            <tbody class="cursor-pointer">
              <tr
                v-for="venta in ventas"
                :key="venta.id"
                @click="redirigir(venta.id)"
              >
                <td class="bg-white text-left text-xl text-center">{{ venta.hora }}</td>
                <td class="bg-white text-left text-xl text-center">{{ venta.id }}</td>
                <td class="bg-white text-left text-xl text-center">$ {{ venta.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-1/3 p-5 flex flex-col justify-center items-center">
        <div class="w-full max-w-screen-xl">
          <table class="w-full border-green-600 border-2 rounded-lg" style="margin-top: 17%; margin-left: -14%;">
            <tbody>
              <tr>
                <td class="bg-custom-green text-left py-4 px-6 text-xl font-bold text-center">
                  Gastos:
                </td>
                <td class="bg-white text-left py-4 px-6 text-xl text-center">
                  $ {{ info_turnos.gastos }}
                </td>
              </tr>
              <tr>
                <td class="bg-custom-green text-left py-4 px-6 text-xl font-bold text-center">
                  Ventas:
                </td>
                <td class="bg-white text-left py-4 px-6 text-xl text-center">
                  $ {{ info_turnos.ventas }}
                </td>
              </tr>
              <tr>
                <td class="bg-custom-green text-left py-4 px-6 text-xl font-bold text-center">
                  Total:
                </td>
                <td class="bg-white text-left py-4 px-6 text-xl text-center">
                  $ {{ info_turnos.total }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Envuelve los botones en un div y aplica estilos -->
        <div class="flex justify-center items-center mt-10">
          <button
            @click="terminarTurno"
            class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-10 rounded-full mr-12"
          >
            Aceptar
          </button>
          <button
            @click="volver"
            class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-10 rounded-full mr-28"
          >
            Volver
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import { Spinner } from "vue-spinners";
export default {
  name: "CorteDeCaja",
  components: {
    Spinner,
  },
  data() {
    return {
      ventas: [],
      items: ["Gastos:", "Ventas:", "Total:"],
      info_turnos: "",
      loading: true,
    };
  },
  methods: {
    volver() {
      router.push("/perfil");
    },
    redirigir($id) {
      this.$id_selected.value2 = $id;
      router.push("/detalleventa");
    },
    terminarTurno() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      axios
        .get(
          `${this.$ruta.value}/finishTurn/${localStorage.getItem("turn")}`,
          config
        )
        .then((response) => {
          alert(response.data.message);
          this.logout();
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    logout() {
      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` },
      };
      axios
        .get(`${this.$ruta.value}/logout`, config)
        .then((response) => {
          alert(response.data.message);
          localStorage.removeItem("Token");
          localStorage.removeItem("logged");
          localStorage.removeItem("sucursal");
          localStorage.removeItem("Token");
          localStorage.removeItem("id");
          localStorage.removeItem("turn");
          localStorage.removeItem("userType");
          this.$sucursal.value = false;
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error al enviar el mensaje:", error);
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 422)
          ) {
            this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
          } else {
            //this.mostrarError = true;
          }
        });
    },
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    axios
      .get(
        `${this.$ruta.value}/getTurn/${localStorage.getItem("turn")}`,
        config
      )
      .then((response) => {
        this.info_turnos = response.data;
        this.ventas = response.data.lista_ventas;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}
.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}
</style>
