<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="flex justify-center items-start h-screen">
      <div class="w-full max-w-screen-xl mt-10">
        <h2 class="text-3xl font-bold mb-4 text-black">Pedidos</h2>
        <br />
        <div
          class="overflow-x-auto md:overflow-x-visible md:overflow-y-visible"
        >
          <table
            class="bg-custom-green w-full border-green-600 border-2 rounded-lg"
          >
            <thead>
              <tr class="bg-custom-green">
                <th class="py-4 px-6 text-xl">ID</th>
                <th class="py-4 px-6 text-xl">Fecha</th>
                <th class="py-4 px-6 text-xl">Sucursal</th>
                <th class="py-4 px-6 text-xl">Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="pedido in pedidos"
                :key="pedido.id"
                @click="redirigir(pedido.id)"
                class="cursor-pointer"
              >
                <td class="bg-white text-center text-xl">{{ pedido.id }}</td>
                <td class="bg-white text-center text-xl">{{ pedido.fecha }}</td>
                <td class="bg-white text-center text-xl">
                  {{ pedido.sucursal }}
                </td>
                <td
                  :class="{
                    'text-green-500 bg-white text-xl font-bold': pedido.estado === '1',
                    'bg-white': pedido.estado !== '1',
                  }"
                  class="text-center text-xl"
                >
                  <div v-if="pedido.estado === '1'">Entregado</div>
                  <div v-else>Sin entregar</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import { Spinner } from "vue-spinners";
export default {
  name: "PedidosComp",
  components: {
    Spinner,
  },
  data() {
    return {
      pedidos: [],
      loading: true,
    };
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` },
    };
    let ruta;
    if (localStorage.getItem("userType") === "Admin") {
      ruta = `${this.$ruta.value}/showOrders`;
    } else {
      if (localStorage.getItem("userType") === "Worker") {
        ruta = `${this.$ruta.value}/showOrdersBranch/${localStorage.getItem(
          "sucursal"
        )}`;
      }
    }
    axios
      .get(ruta, config)
      .then((response) => {
        this.pedidos = response.data.data;
      })
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    redirigir($id) {
      this.$id_selected.value = $id;
      router.push("/detallepedidos");
    },
  },
};
</script>

<style scoped>
table th,
table td {
  padding: 15px;
  border: 2px solid #009929;
}

.bg-custom-green {
  background-color: rgba(92, 203, 95, 0.4);
}
</style>
