<template>
  <div v-if="loading">
    <Spinner :size="{ width: '2em', height: '2em' }" class="style-spinner" />
  </div>
  <div v-else>
    <div class="max-w-screen-lg mx-auto">
      <div class="flex items-center mt-20">
        <h2 class="text-3xl font-bold text-black" style="margin-left: 15.3%;">{{ articulo.nombre }}</h2>
      </div>

      <div class="flex flex-col items-center">
        <div class="flex sm:flex-row justify-center mb-4 sm:mb-0">
          <div class="flex flex-col w-full sm:w-1/2 sm:mr-8">
            <div class="flex items-center mb-4 mt-12 sm:mt-0 sm:ml-2 sm:justify-start" style="margin-left: 31%;">
              <label :for="labelText" class="text-right md:mt-14 text-xl">{{ labelText }}:</label>
              <input type="text" :id="labelText" v-model="cantidad"
                class="border-green-600 border rounded-md px-2 py-1 ml-2 sm:w-2/5 w-full md:mt-14 text-lg"
                @input="restrictToNumbers" />
            </div>
          </div>
          <div class="flex flex-col w-full sm:w-1/2 sm:ml-8">
            <input type="file" accept="image/*" @change="cargarImagen" class="hidden" id="input-imagen" />
            <label for="input-imagen" class="cursor-pointer">
              <img :src="imagen" class="w-full sm:w-1/2 h-2/3 mx-auto" />
            </label>
            <button @click="cambiarImagen" class="btn verde mx-auto">Cambiar Imagen</button>
          </div>
        </div>
      </div>
      <div class="mb-4 sm:mb-0" style="margin-top: -13%; margin-left: 13.5%;">
        <Teclado @input-updated="updateCantidad" />
      </div>
      <div class="flex flex-col sm:flex-row justify-center items-center w-full mb-8 mx-auto" style="margin-left: 23%; margin-top: -3%;">
        <div class="flex flex-row items-center mt-4">
          <div v-if="tipo">
            <button @click="cambiarPrecio" class="btn verde flex justify-between items-center ml-2">
              <span class="mr-1">Aceptar</span>
            </button>
          </div>
          <div v-else>
            <button @click="cambiarCantidad" class="btn verde flex justify-between items-center">
              <span class="mr-1">Aceptar</span>
            </button>
          </div>
          <button @click="goBack" class="btn rojo ml-2 flex justify-between items-center" style="margin-left: 12%;">
            <span class="mr-1">Volver</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Teclado from "@/components/TecladoComp.vue";
import axios from "axios";
import { Spinner } from "vue-spinners";

export default {
  components: {
    Teclado,
    Spinner
  },
  mounted() {
    const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .get(`${this.$ruta.value}/article/${this.$id_selected.value2}`
        , config)
      .then((response) => {
        this.articulo = response.data;
        this.imagen = response.data.imagen;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.error("Error al enviar el mensaje:", error);
      });
  },
  methods: {
    restrictToNumbers(event) {
      // Filtrar el contenido para permitir solo números
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    },
    goBack() {
      window.history.back();
    },

    cargarImagen(event) {
      this.archivoImagen = event.target.files[0];
      this.imagen = URL.createObjectURL(this.archivoImagen);
    },

    cambiarPrecio() {
      if (confirm('¿Estás seguro de que deseas cambiar el precio?')) {
        const config = {
          headers: { Authorization: `Bearer ${this.$token.value}` }
        };
        axios
          .put(`${this.$ruta.value}/updatePrice/${this.$id_selected.value2}`,
            {
              price: this.cantidad
            }
            , config)
          .then(
            window.history.back()
          )
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    },
    cambiarCantidad() {
      if (confirm('¿Estás seguro de que deseas agregar esta cantidad?')) {
        const config = {
          headers: { Authorization: `Bearer ${this.$token.value}` }
        };
        axios
          .put(`${this.$ruta.value}/addAmount/${this.$id_selected.value3}`,
            {
              amount: this.cantidad
            }
            , config)
          .then(
            window.history.back()
          )
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    },
    updateCantidad(value) {
      this.cantidad = value;
    },
    cambiarImagen() {
      const formData = new FormData();
      formData.append('image', this.archivoImagen);

      const config = {
        headers: { Authorization: `Bearer ${this.$token.value}` }
      };

      axios.post(`${this.$ruta.value}/uploadImage/${this.$id_selected.value2}`, formData, config)
        .then((response) => {
          alert(response.data.message);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
  },
  data() {
    return {
      cantidad: 0,
      articulo: [],
      imagen: require('@/assets/manzana.png'),
      archivoImagen: '',
      loading: true
    };
  },
  props: {
    labelText: {
      type: String,
      required: true
    },
    tipo: {
      type: Boolean,
      required: true
    }
  },
};
</script>
