<template>
  <div class="max-w-screen-lg mx-auto">
    <div class="flex items-center mt-12"> <!-- Ajuste del margen superior -->
      <h2 class="text-3xl font-bold text-black" style="margin-left: 18%;">Registrar Producto</h2>
    </div>

    <div class="flex flex-col items-center mt-10">
      <div class="flex sm:flex-row justify-center mb-8 sm:mb-0"> <!-- Ajuste del margen inferior -->
        <div class="flex flex-col w-full sm:w-1/2 sm:mr-8" style="margin-left: -2.5%;">
          <div class="flex items-center mb-4 mt-8 sm:mt-0 sm:ml-2 sm:justify-end"> <!-- Ajuste del margen superior -->
            <label for="nombre" class="text-right md:mt-4 mb-8 text-xl">Nombre:</label> <!-- Alineación vertical -->
            <input type="text" id="nombre" v-model="nombre"
              class="border-green-600 border rounded-md px-2 py-1 ml-2 sm:w-2/5 w-full mb-5 text-lg"  />
          </div>
          <div class="flex items-center -mb-4 sm:mt-0 sm:ml-2 sm:justify-end">
            <label for="precio" class="text-right mb-5 text-xl">Precio:</label> <!-- Alineación vertical -->
            <input type="text" id="precio" v-model="precio"
              class="border-green-600 border rounded-md px-2 py-1 ml-2 sm:w-2/5 w-full mb-5 text-lg"
              @input="restrictToNumbers"/>
          </div>
        </div>
        <div class="flex flex-col w-full sm:w-1/2 sm:ml-8">
          <input type="file" accept="image/*" @change="cargarImagen" class="hidden" id="input-imagen" />
          <label for="input-imagen" class="cursor-pointer">
            <img :src="imagen" class="w-full sm:w-1/2 h-2/3 mx-auto" />
          </label>
        </div>
      </div>
    </div>

    <div class="flex flex-col sm:flex-row justify-center items-center w-full mt-2 mb-8 mx-auto">
      <div class="mb-4 sm:mb-0" style="margin-right: 19%; margin-top: -4%;">
        <Teclado @input-updated="updatePrecio"/>
      </div>
      <div class="flex flex-col items-center ">
        <button @click="register" class="btn verde mb-3 flex justify-between items-center" style="margin-right: 22%;">
          <span class="mr-1">Guardar</span>
        </button>

        <button @click="goBack" class="btn rojo mb-3 flex justify-between items-center" style="margin-right: 22%;">
          <span class="mr-1">Volver</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Teclado from "@/components/TecladoComp.vue";
import axios from "axios"
export default {
  components: {
    Teclado,
  },
  methods: {
    cargarImagen(event) {
      this.archivoImagen = event.target.files[0];
      this.imagen = URL.createObjectURL(this.archivoImagen);
    },
    restrictToNumbers(event) {
    // Filtrar el contenido para permitir solo números
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    },
    goBack() {
      window.history.back();
    },
   
    register(){
      const formData = new FormData();
      formData.append('image', this.archivoImagen);
      formData.append('name', this.nombre);
      formData.append('price', this.precio);
      const config = {
      headers: { Authorization: `Bearer ${this.$token.value}` }
    };
    axios
      .post(`${this.$ruta.value}/registerArticle`,
      formData
      ,config)
      .then(
        window.history.back()
      )
      .catch((error) => {
        console.error("Error al enviar el mensaje:", error);

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 422)
        ) {
          this.errorMsg = "Credenciales inválidas.\nIntenta nuevamente.";
        } else {
          //this.mostrarError = true;
        }
      }); 
    },
    updatePrecio(value) {
      this.precio = value;
    },
  },
  data() {
    return {
      nombre: "",
      precio: 0,
      imagen:require('@/assets/manzana.png'),
      archivoImagen:''
    };
  },
  props: {
    labelText: {
      type: String,
      required: true
    }
  }
};
</script>