<template>
    <NuevoUsuario></NuevoUsuario>
  
  </template>
  
  <script>
  import NuevoUsuario from "../components/NuevoUsuarioComp.vue";
  
  export default {
    name: "NuevoUsuarioView",
    components: {
      NuevoUsuario,
      
    },
  };
  </script>